import { Theme, WithStyles } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import React from "react"
import { BOOKING_LAYOUT_URL } from "../../routes"
import Router from "../../utils/Router"

export interface IProps {}

const styles = (theme: Theme) => {
    return {
        root: {
            paddingTop: theme.spacing(20),
            overflow: "hidden",
        },
        content: {
            padding: theme.spacing(0),
        },
        stepper: {
            padding: `0px ${theme.spacing(24)}px`,
            [theme.breakpoints.down("md")]: {
                padding: `0px  ${theme.spacing(18)}px`,
            },
            [theme.breakpoints.down("sm")]: {
                padding: `0px  ${theme.spacing(12)}px`,
            },
            [theme.breakpoints.down("xs")]: {
                padding: `0px  ${theme.spacing(3)}px`,
            },
        },
    }
}

const Booking: React.FunctionComponent<IProps & WithStyles<typeof styles>> = props => {
    const { classes } = props
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Router layout={BOOKING_LAYOUT_URL} />
            </div>
        </div>
    )
}

export default withStyles(styles)(Booking)
