import React from "react"
import { IHotel } from "../../interfaces/IHotel"
import MapBoxComponent from "./MapBox"

interface IMapStateToProps {
    hotels: IHotel[]
}

type IProps = IMapStateToProps

const MapBox: React.FunctionComponent<IProps> = props => {
    return <MapBoxComponent {...props} />
}

export default MapBox
