import React, { useState } from "react"
import { CardMedia, Theme } from "@material-ui/core"
import cn from "classnames"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import IconButton from "@material-ui/core/IconButton"
import { IImage } from "../../interfaces/IHotel"
import { Swipeable } from "react-swipeable"
import CarouselDots from "./CarouselDots"

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            width: "100%",
            height: "100%",
            maxHeight: 500,
            [theme.breakpoints.down("sm")]: {
                maxHeight: 400,
            },
            [theme.breakpoints.down("xs")]: {
                maxHeight: 300,
            },
            overflowX: "hidden",
            overflowY: "hidden",
            position: "relative",
        },
        carouselContainer: {
            display: "flex",
            alignItems: "stretch",
            flexDirection: "row",
        },
        arrowIcon: {
            color: "white",
            borderRadius: "50%",
            backgroundColor: "rgba(100,100,100, 0.7)",
        },
        arrowButton: {
            position: "absolute",
            zIndex: 1,
            top: "50%",
            transform: "translate(0, -50% )",
        },
        arrowButtonLeft: {
            left: 0,
        },
        arrowButtonRight: {
            right: 0,
        },
        hiddenButton: {
            display: "none",
        },
        image: {
            minWidth: "100%",
            objectFit: "cover",
        },
    })
type IProps = WithStyles<typeof styles> & { images: IImage[]; width?: number; hover: boolean }

const ImageCarouselComponent: React.FunctionComponent<IProps> = props => {
    const { images, classes, hover } = props
    const [displayedImage, setDisplayedImage] = useState(0)
    const numberOfImages = images.length
    const canMoveLeft = () => {
        return displayedImage > 0
    }
    const canMoveRight = () => {
        return displayedImage < numberOfImages - 1
    }
    const moveLeft = () => {
        canMoveLeft() ? setDisplayedImage(displayedImage - 1) : void 0
    }

    const moveRight = () => {
        canMoveRight() ? setDisplayedImage(displayedImage + 1) : void 0
    }
    return (
        <Swipeable className={classes.root} onSwipedLeft={moveRight} onSwipedRight={moveLeft}>
            <div
                className={classes.carouselContainer}
                style={{
                    transform: `translateX(${-displayedImage * 100}%)`,
                    transition: "transform 0.5s",
                }}
            >
                {images.map((image, key) => (
                    <CardMedia component="img" src={image.url} key={key} className={classes.image} />
                ))}
            </div>
            {hover && (
                <>
                    <IconButton
                        onClick={moveLeft}
                        className={
                            displayedImage === 0
                                ? classes.hiddenButton
                                : cn(classes.arrowButton, classes.arrowButtonLeft)
                        }
                    >
                        <KeyboardArrowLeftIcon fontSize="large" className={classes.arrowIcon} />
                    </IconButton>
                    <IconButton
                        onClick={moveRight}
                        className={
                            displayedImage === numberOfImages - 1
                                ? classes.hiddenButton
                                : cn(classes.arrowButton, classes.arrowButtonRight)
                        }
                    >
                        <KeyboardArrowRightIcon fontSize="large" className={classes.arrowIcon} />
                    </IconButton>
                </>
            )}
            <CarouselDots displayedImage={displayedImage} numberOfImages={numberOfImages} />
        </Swipeable>
    )
}

export default withStyles(styles)(ImageCarouselComponent)
