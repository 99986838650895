import { TextField, Theme } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { DatePicker } from "@material-ui/pickers"
import { WithStyles } from "@material-ui/styles"
import * as dateFns from "date-fns"
import { nb } from "date-fns/locale"
import React from "react"
import { IProps as ParentProps } from "./"

const styles = (theme: Theme) => ({
    root: {
        paddingLeft: theme.spacing(0),
    },
})

type IProps = WithStyles<typeof styles> & ParentProps
let idCount = 0
const DatePickerComponent: React.FunctionComponent<IProps> = props => {
    const { label, required, onChange, value, name, minDate, maxDate, select, error } = props
    return (
        <DatePicker
            id={`DatePicker-${name}-${idCount++}`}
            select={select}
            disablePast
            onChange={date => onChange(name, date)}
            value={value}
            label={label}
            required={required}
            labelFunc={(date: Date | null, invalidLabel: string) => {
                if (date) {
                    return dateFns.format(date, "d. MMMM", { locale: nb })
                }
                return invalidLabel
            }}
            autoOk
            error={!!error}
            helperText={error}
            fullWidth
            variant={"dialog"}
            minDate={minDate}
            maxDate={maxDate}
            TextFieldComponent={textFieldProps => <TextField {...textFieldProps} margin={"normal"} />}
        />
    )
}

export default withStyles(styles)(DatePickerComponent)
