import { FormHelperText, Slider, Theme } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import { withStyles } from "@material-ui/core/styles"
import { WithStyles } from "@material-ui/styles"
import React, { useState } from "react"
import TextField from "../TextField"
import { IProps as ParentProps } from "./"

const styles = (theme: Theme) => ({
    root: {
        paddingLeft: theme.spacing(0),
    },
    valueLabel: {},
})

type IProps = ParentProps & WithStyles<typeof styles>

let idCounter: number = 0

const SliderComponent: React.FunctionComponent<IProps> = props => {
    const { classes, label, required, onChange, value, name, error, min, max, withTextField, textFieldProps } = props
    const [val, setVal] = useState(value)

    const id = `Slider-${name}-${idCounter++}`
    return (
        <FormControl className={classes.root} required={required} fullWidth margin={"normal"}>
            {withTextField ? (
                <TextField
                    type={"number"}
                    label={label}
                    variant={textFieldProps ? textFieldProps.variant : "standard"}
                    value={val}
                    onChange={(n, tfVal) => {
                        onChange(name, tfVal)
                        setVal(tfVal)
                    }}
                    name={name}
                />
            ) : (
                <InputLabel htmlFor={id}>{label}</InputLabel>
            )}
            <Slider
                id={!withTextField ? id : ""}
                onChange={(e, val) => setVal(val)}
                onChangeCommitted={(e, val) => onChange(name, val as number)}
                value={val}
                min={min}
                max={max}
            />
            <FormHelperText error={!!error}>{error}</FormHelperText>
        </FormControl>
    )
}

export default withStyles(styles)(SliderComponent)
