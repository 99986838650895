import { Theme, withStyles } from "@material-ui/core/styles"
import MuiPhoneNumber from "material-ui-phone-number"
import React from "react"
import { PERMITTED_LOCALES } from "../../../validators/bookingRequest.validator"

const styles = (theme: Theme) => ({
    root: {
        width: "100% !important",
        margin: "0 important",
    },
})

const PhoneFieldComponent = props => {
    const { classes, value, error, onChange, onTouched, name, required, label } = props

    return (
        <MuiPhoneNumber
            onBlur={onTouched}
            className={classes.root}
            error={!!error}
            helperText={error}
            defaultCountry={"no"}
            onChange={(val: string) => onChange(name, val.replace(/ /g, ""))}
            onlyCountries={PERMITTED_LOCALES.map(locale => locale.split("-")[1].toLowerCase())}
            value={value}
            required={required}
            label={label}
        />
    )
}

export default withStyles(styles)(PhoneFieldComponent)
