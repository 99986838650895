import React from "react"
import { IImage } from "../../interfaces/IHotel"
import ImageCarouselComponent from "./ImageCarousel"

type IProps = {
    images: IImage[]
    hover: boolean
}

const ImageCarousel: React.FunctionComponent<IProps> = props => {
    const { images, hover } = props
    return <ImageCarouselComponent images={images} hover={hover} />
}

export default ImageCarousel
