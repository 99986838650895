import { TextField, Theme } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import Grid, { GridProps } from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import { withStyles } from "@material-ui/core/styles"
import { TextFieldProps } from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Autocomplete from "@material-ui/lab/Autocomplete"
import DropDownIcon from "@material-ui/icons/ArrowDropDown"
import { DatePicker } from "@material-ui/pickers"
import { WithStyles } from "@material-ui/styles"
import * as dateFns from "date-fns"
import { nb } from "date-fns/locale"
import React from "react"
import { ITouched } from "../../interfaces/IBookingRequest"
import { ICity } from "../../interfaces/ICity"
import { IEventTypesState, IBookingRequestState } from "../../store"
import { ICitiesState } from "../../store"
import { IErrors } from "../../validators/bookingRequest.validator"
import { IFormValues, IOnChange } from "./index"

const styles = (theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.info.main,
        "& .MuiSelect-root": {
            width: "25ch",
        },
        [theme.breakpoints.down("xl")]: {
            padding: theme.spacing(7),
        },
        [theme.breakpoints.down("lg")]: {
            padding: theme.spacing(4),
        },
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
    form: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1),
        },
    },
    submitButtonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "0px !important",
    },
    submitButton: {
        width: "100%",
        height: "100%",
        [theme.breakpoints.down("xs")]: {
            width: "auto",
            height: "auto",
        },
    },
})

interface IBookingFormComponentProps {
    bookingRequest: IBookingRequestState
    values: IFormValues
    errors: IErrors<IFormValues>
    onChangeValue: IOnChange
    citiesState: ICitiesState
    eventTypesState: IEventTypesState
    isFormValid: () => boolean
    onSubmit: () => void
    onTouched: (name: string) => () => void
    touched: ITouched<IFormValues>
}

const GridItem: React.FunctionComponent<GridProps> = props => {
    return <Grid xs={6} sm={6} md={4} lg={2} item {...props} />
}

const BookingFormComponent: React.FunctionComponent<IBookingFormComponentProps & WithStyles<typeof styles>> = props => {
    const {
        bookingRequest: {
            data: { styreWeb },
        },
        classes,
        values,
        onChangeValue,
        citiesState: { data, fetching },
        eventTypesState,
        isFormValid,
        onSubmit,
        errors,
        onTouched,
        touched,
    } = props
    const options = data?.sort((a: ICity, b: ICity) => a.name.localeCompare(b.name, "nb"))
    let eventTypes = eventTypesState?.data
    //filtrer ut irrelevante eventTypes hvis det er styrewebkunder
    eventTypes = styreWeb && eventTypes? eventTypes.filter(
        eventType =>
            !["Bryllup", "Konfirmasjon", "Hjemmekontor", "Nettverksmøte"].includes(
                eventType.name,
            ),
        ) : eventTypes
    const eventTypesFetching = eventTypesState && eventTypesState.fetching
    return (
        <Grid container direction="column" className={classes.root}>
            <Typography variant={"body1"}>Finn ditt lokale her</Typography>
            <form className={classes.form}>
                <Grid container direction="row" spacing={3}>
                    <GridItem xs={12}>
                        <Autocomplete
                            onChange={(event: any, value: ICity | null) => {
                                if (value) {
                                    onChangeValue("city")(value)
                                }
                            }}
                            options={options ? options : []}
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) => option.name === value.name}
                            value={values.city || null}
                            autoHighlight
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    onBlur={onTouched("city")}
                                    required={true}
                                    label="Sted"
                                    placeholder="Velg by"
                                    fullWidth
                                    error={touched.city && !!errors.city}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {fetching ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </GridItem>
                    <GridItem>
                        <DatePicker
                            required={true}
                            error={touched.from && !!errors.from}
                            helperText={touched.from && errors.from}
                            labelFunc={(date: Date | null, invalidLabel: string) => {
                                if (date) {
                                    return dateFns.format(date, "d. MMMM", { locale: nb })
                                }
                                return invalidLabel
                            }}
                            TextFieldComponent={(textFieldProps: TextFieldProps): any => {
                                return <TextField {...textFieldProps} fullWidth={true} onBlur={onTouched("from")} />
                            }}
                            autoOk
                            disablePast
                            label={"Dato fra"}
                            invalidLabel={undefined}
                            onClose={onTouched("from")}
                            initialFocusedDate={null}
                            value={values.from || null}
                            onChange={(date: Date | null) => onChangeValue("from")(date as Date)}
                        />
                    </GridItem>
                    <GridItem>
                        <DatePicker
                            required={true}
                            error={touched.to && !!errors.to}
                            helperText={touched.to && errors.to}
                            labelFunc={(date: Date | null, invalidLabel: string) => {
                                if (date) {
                                    return dateFns.format(date, "d. MMMM", { locale: nb })
                                }
                                return invalidLabel
                            }}
                            TextFieldComponent={(textFieldProps: TextFieldProps): any => {
                                return <TextField {...textFieldProps} fullWidth={true} />
                            }}
                            disablePast
                            autoOk
                            onClose={onTouched("to")}
                            label={"Dato til"}
                            invalidLabel={undefined}
                            initialFocusedDate={null}
                            value={values.to || null}
                            minDate={values.from}
                            onChange={(date: Date | null) => onChangeValue("to")(date as Date)}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <TextField
                            onBlur={onTouched("numberOfGuests")}
                            required={true}
                            error={touched.numberOfGuests && !!errors.numberOfGuests}
                            helperText={touched.numberOfGuests && errors.numberOfGuests}
                            label={styreWeb ? "Antall deltakere (ca.)" : "Antall gjester"}
                            type={"number"}
                            value={values.numberOfGuests || ""}
                            onChange={e => onChangeValue("numberOfGuests")(parseInt(e.target.value, 10))}
                            fullWidth
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <TextField
                            onBlur={onTouched("eventTypes")}
                            required={true}
                            error={touched.eventTypes && !!errors.eventTypes}
                            helperText={touched.eventTypes && errors.eventTypes}
                            select
                            placeholder="..."
                            label={"Type arrangement"}
                            value={values.eventTypes || ""}
                            onChange={e => onChangeValue("eventTypes")([parseInt(e.target.value, 10)])}
                            fullWidth
                            SelectProps={{
                                disabled: eventTypesFetching,
                                IconComponent: iconComponentProps =>
                                    eventTypesFetching || !eventTypes ? (
                                        <CircularProgress size={20} {...iconComponentProps} />
                                    ) : (
                                        <DropDownIcon {...iconComponentProps} />
                                    ),
                            }}
                        >
                            {!eventTypesFetching && eventTypes ? (
                                eventTypes
                                    .map(eventTypes => (
                                        <MenuItem key={eventTypes.id} value={eventTypes.id}>
                                            {eventTypes.name}
                                        </MenuItem>
                                    ))
                            ) : (
                                <MenuItem key={"Fetching"} value={"Fetching"}>
                                    Velg type arrangement
                                </MenuItem>
                            )}
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} className={classes.submitButtonContainer}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={onSubmit}
                            disabled={!isFormValid()}
                            className={classes.submitButton}
                        >
                            Søk
                        </Button>
                    </GridItem>
                </Grid>
            </form>
        </Grid>
    )
}

export default withStyles(styles)(BookingFormComponent)
