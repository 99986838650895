import React from "react"
import RatingComponent from "./Rating"

export type IProps = {
    rating: number,
    ratingColor?: string,
}

class Rating extends React.Component<IProps> {
    public render() {
        return <RatingComponent {...this.props} />
    }
}

export default Rating
