import React from "react"
import { RouteComponentProps } from "react-router"
import ReferalsComponent from "./Referals"

type IProps = RouteComponentProps

class Referals extends React.Component<IProps, {}> {
    public render() {
        return <ReferalsComponent />
    }
}

export default Referals
