import { IRoute } from "./interfaces/IRoute"
import ContactInfo from "./views/BookingRequestInfo"
import ConfirmationPage from "./views/Confirmation"
import Landing from "./views/Landing"
import StyreWebLanding from "./views/StyreWebLanding"
import Error from "./views/Error"
import Filter from "./views/PickHotels"
import Summary from "./views/Summary"
import AboutUs from "./views/AboutUs"
import Referals from "./views/Referals"

export const BOOKING_URL = "/booking"
export const BOOKING_LAYOUT_URL = "/booking"
export const ERROR_LAYOUT_URL = "/error"
export const LANDING_LAYOUT_URL = "/"
export const ABOUT_US_LAYOUT_URL = "/aboutus"
export const REFERALS_LAYOUT_URL = "/referals"
export const STYREWEB_LANDING_LAYOUT_URL = "/styreweb"


export const ERROR_PATH = "/error"
export const FILTER_VIEW_PATH = "/filter"
export const CONTACT_INFORMATION_VIEW_PATH = "/info"
export const CONFIRMATION_VIEW_PATH = "/confirmation"
export const SUMMARY_VIEW_PATH = "/summary"
export const ABOUT_US_VIEW_PATH = "/aboutus"
export const REFERALS_VIEW_PATH = "/referals"

export const FILTER_VIEW_URL = BOOKING_LAYOUT_URL + FILTER_VIEW_PATH
export const CONTACT_INFORMATION_VIEW_URL = BOOKING_LAYOUT_URL + CONTACT_INFORMATION_VIEW_PATH
export const CONFIRMATION_VIEW_URL = BOOKING_LAYOUT_URL + CONFIRMATION_VIEW_PATH
export const SUMMARY_VIEW_URL = BOOKING_LAYOUT_URL + SUMMARY_VIEW_PATH

const routes: IRoute[] = [
    {
        name: "Vincu",
        path: "",
        layout: LANDING_LAYOUT_URL,
        component: Landing,
    },
    {
        name: "StyreWeb",
        path: "",
        layout: STYREWEB_LANDING_LAYOUT_URL,
        component: StyreWebLanding,
    },
    {
        name: "Error",
        path: ERROR_PATH,
        layout: ERROR_LAYOUT_URL,
        component: Error,
    },
    {
        name: "About Us",
        path: ABOUT_US_VIEW_PATH,
        layout: ABOUT_US_LAYOUT_URL,
        component: AboutUs,
    },
    {
        name: "Referals",
        path: REFERALS_VIEW_PATH,
        layout: REFERALS_LAYOUT_URL,
        component: Referals,
    },
    {
        name: "Booking",
        layout: BOOKING_LAYOUT_URL,
        subRoutes: [
            {
                name: "Finn hoteller",
                path: FILTER_VIEW_PATH,
                component: Filter,
            },
            {
                name: "Forespørsel sendt",
                path: CONFIRMATION_VIEW_PATH,
                component: ConfirmationPage,
            },
            {
                name: "Bekreft forespørsel",
                path: SUMMARY_VIEW_PATH,
                component: Summary,
            },
            {
                name: "Kontaktinformasjon",
                path: CONTACT_INFORMATION_VIEW_PATH,
                component: ContactInfo,
            },
        ],
    },
]

export default routes
