import { isEqual, union } from "lodash"
import { validateBookingRequest } from "../../validators/bookingRequest.validator"
import { IBookingRequestActionReturn } from "../actions/booking"
import { IBookingRequestState } from "../index"
import {
    ADD_HOTEL_TO_BOOKING_REQUEST,
    CHANGE_BOOKING_REQUEST,
    REMOVE_HOTEL_FROM_BOOKING_REQUEST,
    RESET_BOOKING_REQUEST_STATE,
    SET_TOUCHED,
} from "../types"

export default (state, action: IBookingRequestActionReturn): IBookingRequestState => {
    switch (action.type) {
        case CHANGE_BOOKING_REQUEST:
            return {
                ...state,
                data: { ...state.data, ...action.payload },
                errors: validateBookingRequest({ ...state.data, ...action.payload }),
            }

        case ADD_HOTEL_TO_BOOKING_REQUEST:
            const hotelsWithAdded = state.data.hotels
                ? union([...state.data.hotels], [action.payload])
                : [action.payload]
            return {
                ...state,
                data: {
                    ...state.data,
                    hotels: hotelsWithAdded,
                },
                errors: validateBookingRequest({ ...state.data, hotels: hotelsWithAdded }),
            }
        case REMOVE_HOTEL_FROM_BOOKING_REQUEST:
            const hotelsWithRemoved = state.data.hotels
                ? state.data.hotels.filter(hotel => !isEqual(hotel, action.payload))
                : [action.payload]
            return {
                ...state,
                data: {
                    ...state.data,
                    hotels: hotelsWithRemoved,
                },
                errors: validateBookingRequest({ ...state.data, hotels: hotelsWithRemoved }),
            }
        case SET_TOUCHED:
            return {
                ...state,
                touched: {
                    ...state.touched,
                    ...action.payload,
                },
                errors: validateBookingRequest(state.data),
            }

        case RESET_BOOKING_REQUEST_STATE:
            return {
                data: {},
                errors: {},
                touched: {},
            }

        default:
            return state || null
    }
}
