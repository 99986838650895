import { IGetEventTypesActionReturn } from "../actions/eventTypes"
import { GET_EVENT_TYPES_FULFILLED, GET_EVENT_TYPES_PENDING, GET_EVENT_TYPES_REJECTED } from "../types"
import { ReduxState } from "./index"

export default (state: ReduxState["eventTypes"], action: IGetEventTypesActionReturn) => {
    switch (action.type) {
        case GET_EVENT_TYPES_PENDING:
            return {
                ...state,
                fetching: true,
                messages: [],
            }
        case GET_EVENT_TYPES_FULFILLED:
            return {
                ...state,
                fetching: false,
                data: action.payload,
                messages: action.payload.messages,
            }
        case GET_EVENT_TYPES_REJECTED:
            return {
                ...state,
                fetching: false,
                messages: action.payload.messages,
            }
        default:
            return state || null
    }
}
