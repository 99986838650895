import { SliderProps } from "@material-ui/core/Slider"
import React from "react"
import { IOnChange } from "../types"
import SliderComponent from "./Slider"

export type IProps = Omit<SliderProps, "onChange"> & {
    onChange: IOnChange<number>
    name: string
    required?: boolean
    label?: string
    error?: string
    withTextField?: boolean
    textFieldProps?: { variant: "standard" | "outlined" | "filled" }
}

interface IState {}

class Slider extends React.Component<IProps, IState> {
    public render() {
        return <SliderComponent {...this.props} />
    }
}

export default Slider
