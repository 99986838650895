import { IHotel } from "../../interfaces/IHotel"
import hotelsApi from "../../utils/api/hotelsApi"
import { GET_PROMOTED_HOTELS, GET_PROMOTED_HOTELS_FULFILLED, GET_PROMOTED_HOTELS_PENDING, GET_PROMOTED_HOTELS_REJECTED } from "../types"

export const getPromotedHotels: IGetPromotedHotelsAction = (hotelId: number) => {
    return {
        type: GET_PROMOTED_HOTELS,
        payload: hotelsApi.getHotel(hotelId),
    }
}

export interface IGetPromotedHotels {
    type: typeof GET_PROMOTED_HOTELS
    payload: Promise<any>
}

export interface IGetHotelsPending {
    type: typeof GET_PROMOTED_HOTELS_PENDING
}

export interface IGetPromotedHotelsFulfilled {
    type: typeof GET_PROMOTED_HOTELS_FULFILLED
    payload: { data: IHotel } & { messages: any }
}

export interface IGetHotelsRejected {
    type: typeof GET_PROMOTED_HOTELS_REJECTED
    payload: any | { messages: string }
}



export type IGetPromotedHotelsActionReturn = IGetPromotedHotels | IGetPromotedHotelsFulfilled | IGetHotelsRejected | IGetHotelsPending

export type IGetPromotedHotelsAction = (hotelId: number) => IGetPromotedHotelsActionReturn
