import React from "react"
import { Collapse, IconButton, Snackbar } from "@material-ui/core"
import { Theme, withStyles } from "@material-ui/core/styles"
import { createStyles, WithStyles } from "@material-ui/styles"
import { Alert } from "@material-ui/lab"
import CloseIcon from "@material-ui/icons/Close"
import { IErrors } from "../../validators/bookingRequest.validator"

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
    })

type IProps = WithStyles<typeof styles> & {
    isSnackbarOpen: boolean,
    setIsSnackbarOpen: (isSnackbarOpen: boolean) => void,
    errors: IErrors,
}

const ErrorPopupComponent: React.FunctionComponent<IProps> = props => {
    const { isSnackbarOpen, setIsSnackbarOpen, errors } = props

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setIsSnackbarOpen(false)
    }
    
    let errorMessage: string | undefined = undefined
    for (let key in errors) {
        if (errors[key] !== undefined) {
            errorMessage = errors[key]
            break
        }
    }
    return (
        <div>
            {errorMessage &&
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={isSnackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    <Collapse in={isSnackbarOpen}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSnackbarOpen(false)
                                  }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                          {errorMessage}
                        </Alert>
                    </Collapse>  
                </Snackbar>
            }
        </div>
    )
}

export default withStyles(styles)(ErrorPopupComponent)
