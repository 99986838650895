import axios from "axios"
import { IBookingRequestResponseData } from "../../interfaces/IBookingRequest"
import { store } from "../../store"
import { IMessages } from "./types"
const URL = "/forms"

export interface ISendBookingRequestResponse {
    messages: IMessages
    data: {
        bookingRequest: IBookingRequestResponseData
    }
}

export const sendBookingRequest = (): Promise<ISendBookingRequestResponse> => {
    const bookingRequest = store.getState().bookingRequest
    //Need to restructure object to fit with backend API
    const { city, styreWeb, ...restParams } = bookingRequest.data
    const unPopulatedBookingRequest = {
        ...restParams,
        cityId: city?.id,
        hotels: bookingRequest.data.hotels && bookingRequest.data.hotels.map(hotel => hotel.id),
    }
    return axios.post("https://vincu.azurewebsites.net/api/v1" + URL, unPopulatedBookingRequest)
}
