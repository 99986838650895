import React from "react"
import { RouteComponentProps } from "react-router"
import ErrorComponent from "./Error"

type IProps = RouteComponentProps

class Error extends React.Component<IProps, {}> {
    public render() {
        return <ErrorComponent />
    }
}

export default Error
