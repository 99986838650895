import React from "react"
import { Theme } from "@material-ui/core"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import cn from "classnames"

const styles = (theme: Theme) =>
    createStyles({
        controlDots: {
            position: "absolute",
            justifyContent: "flex-start",
            zIndex: 1,
            left: "50%",
            bottom: 1,
            transform: "translate(-50%, 0 )",
            display: "flex",
        },
        dot: {
            height: 8,
            width: 8,
            margin: 3,
            backgroundColor: "rgba(255,255, 255, 0.6)",
            borderRadius: "50%",
            display: "inline-block",
        },
        activeDot: {
            backgroundColor: "rgba(255, 255, 255, 1)",
            height: 10,
            width: 10,
        },
    })

type IProps = WithStyles<typeof styles> & { displayedImage: number; numberOfImages: number }
const CarouselDots: React.FunctionComponent<IProps> = props => {
    const { classes, displayedImage, numberOfImages } = props
    const renderDots = (): JSX.Element => {
        const dots: number[] = []
        for (let i = 0; i < numberOfImages; i++) {
            dots.push(i)
        }
        return (
            <>
                {dots.map(index => (
                    <span
                        key={index}
                        className={index === displayedImage ? cn(classes.activeDot, classes.dot) : classes.dot}
                    ></span>
                ))}
            </>
        )
    }
    return <div className={classes.controlDots}>{renderDots()}</div>
}
export default withStyles(styles)(CarouselDots)
