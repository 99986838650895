import { IreferalsData } from "./interfaces"
const kompisLogo = require("../../assets/img/vincu/KompisLogo.png")

export const data: IreferalsData[] = [
    {
        header: "Øistein Kristiansen", 
        subHeader: "Daglig leder, Kompis Norge AS",
        text: "Jeg hørte første gang om Vincu via sosiale medier. Tjenesten var brukervennlig, enkel og oversiktlig. Fikk raskt svar på min forespørsel fra flere aktører. Vincu skaffet oss enkelt oversikt over ulike tilbydere og mulige alternativer. Jeg anbefaler lett siden til andre med samme behov. Vårt arrangement ble dessverre avlyst for vår del, men ble fulgt opp i ettertid fra aktørene også, for å ønske oss tilbake ved en senere anledning. ",
        logo: kompisLogo
    },
    {
        header: "Benjamin Løland", 
        subHeader: "Styreleder, Beboerforeningen Portalen", 
        text: "Vi valgte å bruke Vincu fordi det var lett å finne riktig hotell, med rette vilkår og riktig plassering for vårt møte. På www.vincu.no fikk vi løst dette på en effektiv og god måte og fikk tilbud fra de hotellene vi ønsket med de kravene vi hadde. Jeg anbefaler gjerne Vincu til alle foreninger, bedrifter, idrettslag og organisasjoner som skal finne riktig hotell til sitt arrangement.",
    },
]