import React from "react"
import DrowdownComponent from "./Dropdown"

export type IProps = {}

class Dropdown extends React.Component<IProps> {
    public render() {
        return <DrowdownComponent {...this.props} />
    }
}

export default Dropdown
