import React from "react"
import { Theme, withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { createStyles, WithStyles } from "@material-ui/styles"
import cn from "classnames"
const small = require("../../assets/img/vincu/adrien-olichon-1aIwLqshekQ-unsplash1.png")
const medium = require("../../assets/img/vincu/adrien-olichon-1aIwLqshekQ-unsplash1@2x.png")
const large = require("../../assets/img/vincu/adrien-olichon-1aIwLqshekQ-unsplash1@3x.png")

const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
            width: "100%",
        },
        aboutVincuImg: {
            maxWidth: "100%",
            maxHeight: "60%",
        },
        redColoredText: {
            color: theme.palette.secondary.main,
        },
        rightWhiteBackground: {
            position: "relative",
            zIndex: 2,
        },
        leftWhiteBackgroundBox: {
            [theme.breakpoints.up("sm")]: {
                marginTop: 25,
                boxShadow: `-${theme.spacing(24)}px 0px 0px 25px rgba(255,255,255,1)`,
            },
        },
        rightWhiteBackgroundBox: {
            position: "absolute",
            top: 0,
            width: "200%",
            height: "100%",
            backgroundColor: "#ffffff",
            overflow: "visible",
            marginLeft: -theme.spacing(6),
        },
        textContainer: {
            paddingLeft: theme.spacing(6),
            [theme.breakpoints.down("md")]: {
                paddingLeft: theme.spacing(4),
            },
        },
        contentContainer: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
            [theme.breakpoints.down("xs")]: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
        },
    })

type IProps = WithStyles<typeof styles>

const AboutVincuComponent: React.FunctionComponent<IProps> = props => {
    const { classes } = props
    return (
        <div className={classes.root}>
            <Grid container direction="row">
                <Grid item xs={12} sm={6} className={classes.contentContainer}>
                    <Hidden smUp>
                        <Typography
                            color="textSecondary"
                            variant={"h2"}
                            align={"center"}
                        >
                            Om Vincu
                        </Typography>
                    </Hidden>
                    <Hidden xsDown>
                        <div className={classes.leftWhiteBackgroundBox}>
                            <img
                                alt={"About vincu"}
                                className={classes.aboutVincuImg}
                                src={small}
                                srcSet={`${small} 300w, ${medium} 768w, ${large} 1280w`}
                            />
                        </div>
                    </Hidden>
                </Grid>
                <Grid item xs={12} sm={6} className={cn(classes.contentContainer, classes.textContainer)}>
                    <Hidden xsDown>
                        <Typography gutterBottom color="textSecondary" variant={"h2"} align={"center"}>
                            Om Vincu
                        </Typography>
                    </Hidden>
                    <div style={{ position: "relative" }}>
                        <Typography variant="body2" paragraph className={classes.rightWhiteBackground}>
                            Vi i Vincu ønsker å forenkle ditt neste arrangement. Når du eller din bedrift/lag/forening
                            planlegger fremtidige arrangementer, innhenter vi pristilbud og tilgjengelighet for deg,
                            <span className={classes.redColoredText}> helt gratis og uforpliktende.</span>
                        </Typography>
                        <div className={classes.rightWhiteBackgroundBox}></div>
                    </div>
                    <Typography variant="body2" color="textSecondary" paragraph>
                        Bak slike arrangementer ligger ofte en tidkrevende dialog med ulike kontaktpunkter før man til
                        slutt finner frem til passende venue. Dette har vi nå gjort noe med og det eneste du trenger å
                        gjøre er å fylle ut skjema på toppen av siden samt skrive noen ord om ditt neste arrangement.
                        Nettsiden vil da automatisk filtrere de hoteller som faktisk har passende lokaler, og du kan
                        helt enkelt velge de aktørene du selv ønsker å motta tilbud fra. Din forespørsel formidles
                        videre, og du vil innen kort tid motta tilbud direkte i din innboks.
                    </Typography>
                    <Typography color="textSecondary" variant="body2" paragraph>
                        Har du spørsmål er du velkommen til å kontakte oss på kontaktinformasjonen øverst på våre sider.
                        Vi ser frem til å høre fra deg!
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(AboutVincuComponent)
