import isEqual from "lodash/isEqual"
import * as React from "react"
import { connect } from "react-redux"
import { IBookingRequest } from "../../interfaces/IBookingRequest"
import { IHotel } from "../../interfaces/IHotel"
import { IIsHotelSelected, IToggleHotel } from "../../interfaces/IPickHotelsView"
import { IReduxState } from "../../store"
import { addHotel, IAddHotelAction, IRemoveHotelAction, removeHotel } from "../../store/actions/booking"
import HotelCardComponent from "./HotelCard"

export interface IHotelCardProps {
    numberForAlternatingColor: number
    hotel: IHotel
    fetching?: boolean
    collapsed?: boolean
    isPromotedHotel?: boolean
}

interface IMapStateToProps {
    bookingRequest: IBookingRequest
}

interface IMapDispatchToProps {
    addHotel: IAddHotelAction
    removeHotel: IRemoveHotelAction
}

type IProps = IHotelCardProps & IMapDispatchToProps & IMapStateToProps

interface IState {
    isSnackbarOpen: boolean
}

class HotelCard extends React.Component<IProps, IState> {
    public constructor(props) {
        super(props)
        this.state = {
            isSnackbarOpen: false,
        }
    }

    public isHotelSelected: IIsHotelSelected = () => {
        const hotels = this.props.bookingRequest.hotels
        return !!hotels && hotels.findIndex(hotel => isEqual(hotel, this.props.hotel)) >= 0
    }

    public toggleHotel: IToggleHotel = (hotel: IHotel, checked) => {
        const bookingRequest = this.props.bookingRequest
        if (!checked) {
            this.props.removeHotel(hotel)
        } else {
            if (bookingRequest && bookingRequest.hotels && bookingRequest.hotels.length + 1 > 5) {
                this.setState({ isSnackbarOpen: true })
            } else {
                this.props.addHotel(hotel)
            }
        }
    }

    public render() {
        return (
            <HotelCardComponent
                {...this.props}
                selected={this.isHotelSelected()}
                toggleHotel={this.toggleHotel}
                isSnackbarOpen={this.state.isSnackbarOpen}
                setSnackbarOpen={open => this.setState({ isSnackbarOpen: open })}
            />
        )
    }
}

const mapStateToProps = (state: IReduxState): IMapStateToProps => {
    return {
        bookingRequest: state.bookingRequest.data,
    }
}

export default connect<IMapStateToProps, IMapDispatchToProps, IHotelCardProps, IReduxState>(mapStateToProps, {
    addHotel,
    removeHotel,
})(HotelCard)
