import React from "react"
import { ILandingLayoutProps } from "../../interfaces/ILandingLayout"
import StyreWebLandingComponent from "./StyreWebLanding"

class Landing extends React.Component<ILandingLayoutProps> {
    public render() {
        return <StyreWebLandingComponent {...this.props} />
    }
}

export default Landing
