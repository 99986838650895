import React from "react"
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { compose } from "redux"
import { IBookingRequest, ITouched } from "../../interfaces/IBookingRequest"
import { ICity } from "../../interfaces/ICity"
import { FILTER_VIEW_URL } from "../../routes"
import { ICitiesState, IEventTypesState, IReduxState, IBookingRequestState } from "../../store"
import {
    changeBookingRequest,
    IChangeBookingRequestAction,
    ISetTouchedAction,
    setTouched,
} from "../../store/actions/booking"
import { getCities, IGetCitiesAction } from "../../store/actions/cities"
import { getEventTypes, IGetEventTypesAction } from "../../store/actions/eventTypes"
import { pick } from "../../utils/object"
import { IErrors } from "../../validators/bookingRequest.validator"
import BookingFormComponent from "./BookingForm"

interface IMapStateToProps {
    citiesState: ICitiesState
    eventTypesState: IEventTypesState
    values: IFormValues
    errors: IErrors<IFormValues>
    touched: ITouched<IFormValues>
    bookingRequest: IBookingRequestState
}

interface IMapDispatchToProps {
    getCities: IGetCitiesAction
    getEventTypes: IGetEventTypesAction
    changeBookingRequest: IChangeBookingRequestAction
    setTouched: ISetTouchedAction
}

interface IBookingFormState {}
export type IOnChange = (name: keyof IFormValues) => (value: ICity | string | number | Date | null | number[]) => void
export type IFormValues = Pick<IBookingRequest, "city" | "from" | "to" | "numberOfGuests" | "eventTypes">
export const formFields: Array<keyof IFormValues> = ["eventTypes", "from", "to", "city", "numberOfGuests"]

type IProps = IMapStateToProps & IMapDispatchToProps & RouteComponentProps
type IState = IBookingFormState

class BookingForm extends React.Component<IProps, IState> {
    public onChangeValue: IOnChange = name => value => {
        this.props.changeBookingRequest({ [name]: value })
    }
    public componentDidMount(): void {
        this.props.getCities()
        this.props.getEventTypes()
    }
    public isFormValid = (): boolean => {
        return Object.keys(this.props.values).every(key => {
            return !!this.props.values[key] && !this.props.errors[key]
        })
    }

    public onSubmit = (): void => {
        this.props.history.push(FILTER_VIEW_URL)
    }

    public onTouched = (name: string) => (): void => {
        console.log("Touched" + name)
        this.props.setTouched({ [name]: true })
    }

    public render() {
        const { citiesState, eventTypesState } = this.props
        return (
            <BookingFormComponent
                citiesState={citiesState}
                eventTypesState={eventTypesState}
                values={this.props.values}
                errors={this.props.errors}
                onChangeValue={this.onChangeValue}
                onTouched={this.onTouched}
                isFormValid={this.isFormValid}
                onSubmit={this.onSubmit}
                touched={this.props.touched}
                bookingRequest={this.props.bookingRequest}
            />
        )
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        bookingRequest: state.bookingRequest,
        citiesState: state.cities,
        eventTypesState: state.eventTypes,
        values: pick<IFormValues>(state.bookingRequest.data, formFields),
        errors: pick<IErrors<IFormValues>>(state.bookingRequest.errors, formFields),
        touched: pick<ITouched<IFormValues>>(state.bookingRequest.touched, formFields),
    }
}

export default compose(
    connect<IMapStateToProps, IMapDispatchToProps, {}, IReduxState>(mapStateToProps, {
        getCities,
        getEventTypes,
        changeBookingRequest,
        setTouched,
    }),
    withRouter,
)(BookingForm) as React.ComponentType
