import React from "react"
import { RouteComponentProps, withRouter } from "react-router"
import { compose } from "redux"
import { connect } from "react-redux"
import { IBookingRequest } from "../../interfaces/IBookingRequest"
import { IReduxState } from "../../store"

import HeaderComponent from "./Header"

interface IMapStateToProps {
    bookingRequest: IBookingRequest
}

interface IProps extends RouteComponentProps {}

interface IState {}

class Header extends React.Component<IProps & IMapStateToProps, IState> {
    public render() {
        return <HeaderComponent {...this.props} />
    }
}

const mapStateToProps = (state: IReduxState): IMapStateToProps => {
    return {
        bookingRequest: state.bookingRequest.data,
    }
}

export default compose(
    connect<IMapStateToProps, {}, {}, IReduxState>(mapStateToProps),
    withRouter,
)(Header) as React.ComponentType
