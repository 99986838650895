import React from "react"
import { Checkbox, FormControlLabel, Grid, Theme, Typography } from "@material-ui/core"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import RoomIcon from "@material-ui/icons/Room"
import { IHotel } from "../../interfaces/IHotel"
import { Marker, Popup } from "react-map-gl"
import { IToggleHotel } from "../../interfaces/IPickHotelsView"
import "./customMapboxStyling.css"
import Rating from "../Rating"
import ErrorPopup from "../ErrorPopup/ErrorPopup"

const styles = (theme: Theme) =>
    createStyles({
        marker: {
            "&:hover": {
                cursor: "pointer !important",
            },
        },
        roomIcon: {
            width: 30,
            height: 30,
        },
        checkbox: {
            color: theme.palette.secondary.main,
        },
        checkboxController: {
            marginLeft: 0,
        },
        popUp: {
            maxWidth: 250,
            zIndex: 2,
        },
    })

type IProps = WithStyles<typeof styles> & {
    index: number
    hotel: IHotel
    allPopups: {}
    setShowAllPopups: (showPopup: {}) => void
    selected: boolean
    toggleHotel: IToggleHotel
    latitude: number
    longitude: number
    isSnackbarOpen: boolean
    setSnackbarOpen: (isSnackbarOpen: boolean) => void
}

const MapMarkerComponent: React.FunctionComponent<IProps> = props => {
    const { classes, index, hotel, allPopups, setShowAllPopups, selected, toggleHotel, setSnackbarOpen,  latitude, longitude, isSnackbarOpen } = props

    return (
        <React.Fragment key={index}>
            <ErrorPopup  errors={{hotels: "Du kan bare velge opp til 5 hoteller på en gang."}} isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setSnackbarOpen} />
            <Marker
                latitude={latitude}
                longitude={longitude}
                offsetLeft={-15}
                offsetTop={-30}
                className={classes.marker}
                //onClick={e => e.stopPropagation()}
            >
                <RoomIcon
                    className={classes.roomIcon}
                    onClick={() => {
                        setShowAllPopups(index)
                    }}
                    color={selected ? "secondary" : "primary"}
                />
            </Marker>
            {allPopups[index] ? (
                <ClickAwayListener
                    mouseEvent="onClick"
                    onClickAway={() => {
                        setShowAllPopups(index)
                    }}
                >
                    <Popup
                        latitude={latitude}
                        longitude={longitude}
                        closeOnClick={false}
                        dynamicPosition={true}
                        onClose={() => setShowAllPopups(index)}
                        anchor="top"
                        className={classes.popUp}
                    >
                        <div>
                            <Typography variant="body1">{hotel.name}</Typography>
                            <Typography variant="body2">{hotel.city}</Typography>
                            {hotel.tripAdvisorRating && <Rating rating={hotel.tripAdvisorRating} />}
                                <Grid item>
                                    <FormControlLabel
                                    className={classes.checkboxController}
                                    control={
                                        <Checkbox
                                        className={classes.checkbox}
                                        checked={selected}
                                        inputProps={{
                                            "aria-label": "primary checkbox",
                                        }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            toggleHotel(hotel, e.target.checked)
                                        }
                                        //Closes popup 0.5 sec after click on checkbox
                                        onClick={() =>
                                            setTimeout(() => setShowAllPopups(index), 250)
                                        }
                                    />
                                    }
                                    label="Velg hotell:"
                                    labelPlacement="start"
                                    />
                                </Grid>
                        </div>
                    </Popup>
                </ClickAwayListener>
            ) : null}
        </React.Fragment>
    )
}

export default withStyles(styles)(MapMarkerComponent)
