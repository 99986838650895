import React from "react"
import { ILandingLayoutComponentProps } from "../../interfaces/ILandingLayout"
import { LANDING_LAYOUT_URL } from "../../routes"
import Router from "../../utils/Router"

const LandingComponent = (props: ILandingLayoutComponentProps) => {
    return (
        <div>
            <Router layout={LANDING_LAYOUT_URL} />
        </div>
    )
}
export default LandingComponent
