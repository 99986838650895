import React from "react"
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { compose } from "redux"
import { IBookingRequest } from "../../interfaces/IBookingRequest"
import { CONFIRMATION_VIEW_URL } from "../../routes"
import { IReduxState } from "../../store"
import { IResetBookingRequestStateAction, resetBookingRequestState } from "../../store/actions/booking"
import { getHotels, IGetHotelsAction } from "../../store/actions/hotels"
import { ISendBookingRequestResponse, sendBookingRequest } from "../../utils/api/bookingRequestApi"
import SummaryComponent from "./Summary"

interface ISummaryProps {}
interface IMapStateToProps {
    bookingRequest: IBookingRequest
}

interface IMapDispatchToProps {
    getHotels: IGetHotelsAction
    resetBookingRequestState: IResetBookingRequestStateAction
}

export type IProps = RouteComponentProps & ISummaryProps & IMapStateToProps & IMapDispatchToProps & RouteComponentProps

class Summary extends React.Component<IProps> {
    public componentDidMount(): void {
        this.props.getHotels()
    }

    public onSubmit: () => void = () => {
        sendBookingRequest()
            .then((res: ISendBookingRequestResponse) => {
                //Lazy fix, should improve
                const holder = {
                    bookingRequest: {
                        ...res,
                    },
                }
                this.props.resetBookingRequestState()
                this.props.history.push(CONFIRMATION_VIEW_URL, holder)
            })
            .catch(err => {
                console.log(err)
            })
    }
    public render() {
        return <SummaryComponent onSubmit={this.onSubmit} bookingRequest={this.props.bookingRequest} />
    }
}

const mapStateToProps = (state: IReduxState): IMapStateToProps => {
    return {
        bookingRequest: state.bookingRequest.data,
    }
}

export default compose(
    connect<IMapStateToProps, IMapDispatchToProps, IProps, IReduxState>(mapStateToProps, {
        getHotels,
        resetBookingRequestState,
    }),
    withRouter,
)(Summary) as React.ComponentType<ISummaryProps>
