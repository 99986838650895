import { DatePickerProps } from "@material-ui/pickers"
import React from "react"
import { IOnChange } from "../types"
import DatePickerComponent from "./DatePicker"

export type IProps = Omit<DatePickerProps, "onChange" | "error" | "name"> & {
    onChange: IOnChange<Date>
    error?: string
    name: string
}

class DatePicker extends React.Component<IProps> {
    public render() {
        return <DatePickerComponent {...this.props} />
    }
}

export default DatePicker
