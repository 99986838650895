import { Theme, WithStyles, withStyles } from "@material-ui/core"
import { StepIconProps } from "@material-ui/core/StepIcon"
import CheckBoxSharpIcon from "@material-ui/icons/CheckBoxSharp"
import cn from "classnames"
import React from "react"

const styles = (theme: Theme) => {
    return {
        root: {
            color: "#eaeaf0",
            display: "flex",
            height: 22,
            alignItems: "center",
        },
        circle: {
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "currentColor",
        },
        diamond: {
            width: 10,
            height: 10,
            transform: "rotate(45deg)",
            borderStyle: "solid",
            borderColor: theme.palette.secondary.light,
            borderWidth: "1px",
        },
        active: {
            backgroundColor: theme.palette.primary.light,
            borderStyle: "none !important",
        },
        completed: {
            color: theme.palette.secondary.light,
            zIndex: 1,
            fontSize: 22,
        },
    }
}

const StepIcon: React.FunctionComponent<StepIconProps & WithStyles<typeof styles>> = props => {
    const { classes, active, completed } = props
    return (
        <div className={classes.root}>
            {completed ? (
                <CheckBoxSharpIcon className={classes.completed} />
            ) : (
                <div className={cn(classes.diamond, { [classes.active]: active })} />
            )}
        </div>
    )
}

export default withStyles(styles)(StepIcon)
