import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { compose } from "redux"
import { IBookingRequest, ITouched } from "../../interfaces/IBookingRequest"
import { CONTACT_INFORMATION_VIEW_URL } from "../../routes"
import { ICitiesState, IReduxState, IBookingRequestState } from "../../store"
import {
    changeBookingRequest,
    IChangeBookingRequestAction,
    ISetTouchedAction,
    setTouched,
} from "../../store/actions/booking"
import { getHotels, IGetHotelsAction } from "../../store/actions/hotels"
import { getCities, IGetCitiesAction } from "../../store/actions/cities"
import { pick } from "../../utils/object"
import { IErrors } from "../../validators/bookingRequest.validator"
import { IOnChange } from "../Fields/types"
import FiltersComponent from "./Filters"

interface IMapStateToProps {
    values: IFormValues
    touched: ITouched<IFormValues>
    errors: IErrors<IFormValues>
    citiesState: ICitiesState
    bookingRequestState: IBookingRequestState
}

interface IMapDispatchToProps {
    getHotels: IGetHotelsAction
    changeBookingRequest: IChangeBookingRequestAction
    setTouched: ISetTouchedAction
    getCities: IGetCitiesAction
}

export type IFormValues = Pick<
    IBookingRequest,
    "city" | "from" | "to" | "hotels" | "numberOfGuests" | "numberOfDinners" | "numberOfBeds" | "numberOfMeetingRooms"
>
export const formFields: (keyof IFormValues)[] = [
    "from",
    "to",
    "hotels",
    "city",
    "numberOfGuests",
    "numberOfDinners",
    "numberOfBeds",
    "numberOfMeetingRooms",
]

type IProps = IMapStateToProps & IMapDispatchToProps & RouteComponentProps

const Filters: React.FunctionComponent<IProps> = props => {
    const [isSnackbarOpen, setIsSnackbarOpen]  = useState<boolean>(false)
    const onChange: IOnChange<keyof IFormValues> = (fieldName, value) => {
        props.changeBookingRequest({ [fieldName]: value })
        props.getHotels()
    }
    const isFormValid = (): boolean => {
        return [...formFields, "hotels"].every(key => {
            return !props.errors[key]
        })
    }
    const onTouched = (name: string) => (): void => {
        props.setTouched({ [name]: true })
    }

    const onSubmit = () => {
        isFormValid() ? props.history.push(CONTACT_INFORMATION_VIEW_URL) : setIsSnackbarOpen(!isSnackbarOpen)
    }
    useEffect(() => {
        if (!props.citiesState) {
            props.getCities()
        }
    }, [props])
    return (
        <FiltersComponent
            isValid={isFormValid}
            onSubmit={onSubmit}
            values={props.values}
            onChange={onChange}
            errors={props.errors}
            touched={props.touched}
            onTouched={onTouched}
            citiesState={props.citiesState}
            bookingRequestState={props.bookingRequestState}
            isSnackbarOpen={isSnackbarOpen}
            setIsSnackbarOpen={setIsSnackbarOpen}
        />
    )
}

const mapStateToProps = (state: IReduxState): IMapStateToProps => {
    return {
        values: pick<IFormValues>(state.bookingRequest.data, formFields),
        errors: pick<IErrors<IFormValues>>(state.bookingRequest.errors, formFields),
        touched: pick<ITouched<IFormValues>>(state.bookingRequest.touched, formFields),
        citiesState: state.cities,
        bookingRequestState: state.bookingRequest,
    }
}

export default compose(
    connect<IMapStateToProps, IMapDispatchToProps, {}, IReduxState>(mapStateToProps, {
        getHotels,
        changeBookingRequest,
        setTouched,
        getCities,
    }),
    withRouter,
)(Filters) as React.ComponentType
