import React from "react"
import TutorialCardComponent from "./TutorialCard"

export type IProps = {
    text: string,
    onClose: () => void,
}

class TutorialCard extends React.Component<IProps> {
    public render() {
        return <TutorialCardComponent {...this.props} />
    }
}

export default TutorialCard