import React from "react"
import { RouteComponentProps } from "react-router"
import { ISendBookingRequestResponse } from "../../utils/api/bookingRequestApi"
import ConfirmationComponent from "./Confirmation"

type IProps = RouteComponentProps<{}, {}, ISendBookingRequestResponse["data"]>

class Confirmation extends React.Component<IProps> {
    public render() {
        return <ConfirmationComponent bookingConfirmation={this.props.history.location.state.bookingRequest} />
    }
}

export default Confirmation
