import { IBookingRequest, ITouched } from "../../interfaces/IBookingRequest"
import { IHotel } from "../../interfaces/IHotel"
import {
    ADD_HOTEL_TO_BOOKING_REQUEST,
    CHANGE_BOOKING_REQUEST,
    REMOVE_HOTEL_FROM_BOOKING_REQUEST,
    RESET_BOOKING_REQUEST_STATE,
    SET_TOUCHED,
} from "../types"

/* -------------- CHANGE BOOKING REQUEST -------------- */

interface IChangeBookingRequestActionReturn {
    type: typeof CHANGE_BOOKING_REQUEST
    payload: IBookingRequest
}

export type IChangeBookingRequestAction = (bookingRequest: IBookingRequest) => IChangeBookingRequestActionReturn

export const changeBookingRequest: IChangeBookingRequestAction = bookingRequest => {
    return {
        type: CHANGE_BOOKING_REQUEST,
        payload: bookingRequest,
    }
}

/* -------------- SET TOUCHED -------------- */

interface ISetTouchedActionReturn {
    type: typeof SET_TOUCHED
    payload: ITouched<IBookingRequest>
}

export type ISetTouchedAction = (touchedFields: ITouched<IBookingRequest>) => ISetTouchedActionReturn

export const setTouched: ISetTouchedAction = touchedFields => {
    return {
        type: SET_TOUCHED,
        payload: touchedFields,
    }
}

/* -------------- ADD HOTEL TO BOOKING REQUEST -------------- */

export interface IAddHotelActionReturn {
    type: typeof ADD_HOTEL_TO_BOOKING_REQUEST
    payload: IHotel
}

export type IAddHotelAction = (hotel: IHotel) => IAddHotelActionReturn

export const addHotel: IAddHotelAction = hotel => {
    return {
        type: ADD_HOTEL_TO_BOOKING_REQUEST,
        payload: hotel,
    }
}

/* -------------- REMOVE HOTEL FROM BOOKING REQUEST -------------- */

export interface IRemoveHotelActionReturn {
    type: typeof REMOVE_HOTEL_FROM_BOOKING_REQUEST
    payload: IHotel
}

export type IRemoveHotelAction = (hotel: IHotel) => IRemoveHotelActionReturn

export const removeHotel: IRemoveHotelAction = hotel => {
    return {
        type: REMOVE_HOTEL_FROM_BOOKING_REQUEST,
        payload: hotel,
    }
}

/* -------------- RESET -------------- */

export interface IResetBookingRequestStateReturn {
    type: typeof RESET_BOOKING_REQUEST_STATE
}

export type IResetBookingRequestStateAction = () => IResetBookingRequestStateReturn

export const resetBookingRequestState: IResetBookingRequestStateAction = (): IResetBookingRequestStateReturn => {
    return {
        type: RESET_BOOKING_REQUEST_STATE,
    }
}

export type IBookingRequestAction =
    | IChangeBookingRequestAction
    | IAddHotelAction
    | IRemoveHotelAction
    | ISetTouchedAction
    | IResetBookingRequestStateAction
export type IBookingRequestActionReturn =
    | IChangeBookingRequestActionReturn
    | IAddHotelActionReturn
    | IRemoveHotelActionReturn
    | ISetTouchedActionReturn
    | IResetBookingRequestStateReturn
