import { FormHelperText } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { withStyles } from "@material-ui/core/styles"
import { createStyles, WithStyles } from "@material-ui/styles"
import React from "react"
import { IProps as ParentProps } from "./"

const styles = theme =>
    createStyles({
        root: {},
    })

type IProps = ParentProps & WithStyles<typeof styles>
let idCounter: number = 0

const SelectFieldComponent: React.FunctionComponent<IProps> = props => {
    const { classes, onChange, label, onTouched, value, error, children, variant, name } = props
    return (
        <FormControl fullWidth margin={"dense"} className={classes.root}>
            <InputLabel shrink={true} htmlFor={`Select-${name}-${idCounter++}`}>
                {label}
            </InputLabel>
            <Select
                id={`Select-${name}-${idCounter}`}
                onBlur={onTouched}
                required={true}
                value={value}
                onChange={e => onChange(name, e.target.value)}
                error={!!error}
                variant={variant as any}
            >
                {children}
            </Select>
            <FormHelperText>{!!error ? error : null}</FormHelperText>
        </FormControl>
    )
}

export default withStyles(styles)(SelectFieldComponent)
