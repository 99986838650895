import { Theme, WithStyles, Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import { Styles } from "@material-ui/styles/withStyles"
import Button from "@material-ui/core/Button"
import cn from "classnames"
import Filters from "../../components/Filters"
import HotelCard from "../../components/HotelCard"
import TutorialCard from "../../components/TutorialCard"
import MapBoxComponent from "../../components/MapBox"
import MapOutlinedIcon from "@material-ui/icons/MapOutlined"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { IHotel } from "../../interfaces/IHotel"
import { ReduxState } from "../../store/reducers"
import Tour from "reactour"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { IBookingRequest } from "../../interfaces/IBookingRequest"

export interface IPickHotelsComponentProps {
    hotelsState: ReduxState["hotels"]
    promotedHotelsState: ReduxState["promotedHotels"]
    bookingRequest: IBookingRequest
    onSubmit: () => void
    isValid: () => boolean
}

const styles: Styles<any, any> = (theme: Theme) => {
    return {
        root: {
            padding: `0px ${theme.spacing(20)}px`,
            [theme.breakpoints.down("md")]: {
                padding: `0px ${theme.spacing(12)}px`,
            },
            [theme.breakpoints.down("sm")]: {
                padding: `0px ${theme.spacing(6)}px`,
            },
            [theme.breakpoints.down("xs")]: {
                padding: `0px ${theme.spacing(3)}px`,
            },
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.main,
        },
        snackbarIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(1),
        },
        snackbarMessage: {
            display: "flex",
            alignItems: "center",
        },
        emptyCityContainer: {
            position: "relative",
            width: "50%",
            [theme.breakpoints.down("sm")]: {
                width: "80%",
            },
            left: "50%",
            transform: "translate(-50%, 0)",
            padding: theme.spacing(4),
            backgroundColor: theme.palette.primary.main,
            marginBottom: "10px",
        },
        section: {},
        filterSection: {},
        mapButton: {
            position: "fixed",
            left: "50%",
            bottom: "7%",
            transform: "translate(-50%, 7%)",
            zIndex: 1,
            borderRadius: "5px !important",
        },
        mapIcon: {
            marginRight: "5px",
        },
    }
}

const PickHotelsComponent: React.FunctionComponent<IPickHotelsComponentProps & WithStyles<typeof styles>> = props => {
    const {
        classes,
        hotelsState: { data, fetching },
        bookingRequest,
        promotedHotelsState,
    } = props

    const [displayMap, setDisplayMap] = useState<boolean>(false)
    const [isTutorialOpen, setIsTutorialOpen] = useState<boolean>(false)
    const [showPromotedHotel, setShowPromotedHotel] = useState<boolean>(false)
    const hasBeenHereBefore = localStorage.getItem("hasBeenOnLandingPageBefore") === "true" ? true : false
    const promotedHotel: IHotel = promotedHotelsState && promotedHotelsState.data
    useEffect(() => {
        if (!fetching && data !== undefined && data.length > 0 && !hasBeenHereBefore) {
            setIsTutorialOpen(true)
            localStorage.setItem("hasBeenOnLandingPageBefore", "true")
        }
        if (bookingRequest && bookingRequest.eventTypes && bookingRequest.eventTypes.length > 0) {
            /*If eventtype is middag, and city.name is Stjørdal, Steinkjer, Orkanger, Trondheim or Hitra, and numberOfGuests >= 50
                -> show promoted hotel. 
            Hardcoded solution while they implement it backend*/
            const promotedEventType = 7 //Middag
            const promotedCities = ["Stjørdal", "Steinkjer", "Orkanger", "Trondheim", "Hitra"]
            if (
                bookingRequest.city?.name &&
                promotedCities.includes(bookingRequest.city?.name) &&
                bookingRequest.eventTypes[0] === promotedEventType &&
                bookingRequest.numberOfGuests &&
                bookingRequest.numberOfGuests >= 50 &&
                bookingRequest.numberOfGuests < 250
            ) {
                setShowPromotedHotel(true)
            } else {
                setShowPromotedHotel(false)
            }
        }
    }, [hasBeenHereBefore, fetching, bookingRequest, data])

    const disableBody = (target: boolean): void => disableBodyScroll(target)
    const enableBody = (target: boolean): void => enableBodyScroll(target)

    // Steps for the guided tutorial. Only one step for the choosing hotels for now, can be extended.
    const tutorialSteps = [
        {
            selector: "#checkbox",
            content: ({ goTo }) => (
                <TutorialCard
                    text="Huk av sjekkbokser for å velge hoteller! Du kan velge så mange du vil, men må velge minst ett."
                    onClose={() => goTo(1)}
                />
            ),
            position: "right",
        },
        {
            selector: "#nextButton",
            content: () => (
                <TutorialCard
                    text="Når du har valgt ønskede hoteller, trykk her for å gå videre!"
                    onClose={() => setIsTutorialOpen(false)}
                />
            ),
        },
    ]
    return (
        <Grid container className={classes.root}>
            <Button
                onClick={() => {
                    setDisplayMap(!displayMap)
                }}
                variant="contained"
                color={displayMap ? "secondary" : "primary"}
                className={classes.mapButton}
            >
                {!displayMap ? (
                    <>
                        <MapOutlinedIcon className={classes.mapIcon} />
                        Kart
                    </>
                ) : (
                    <>
                        <ArrowBackIcon className={classes.mapIcon} />
                        Tilbake
                    </>
                )}
            </Button>

            {displayMap && <MapBoxComponent hotels={data} />}

            {!displayMap && (
                <Grid item md={12} lg={2} xl={2} className={cn(classes.section, classes.filtersSection)}>
                    <Filters />
                </Grid>
            )}

            {!displayMap && (
                <Grid className={classes.section} alignContent={"flex-start"} container item md={12} lg={10} xl={10}>
                    {promotedHotel && showPromotedHotel && (
                        <Grid item xs={12} key={1}>
                            <HotelCard
                                hotel={promotedHotel}
                                fetching={fetching}
                                numberForAlternatingColor={1}
                                isPromotedHotel={true}
                            />
                        </Grid>
                    )}

                    {data &&
                        (data.length > 0 ? (
                            data.map((hotel: IHotel, i: number) => (
                                <Grid item xs={12} key={i}>
                                    <HotelCard
                                        hotel={hotel}
                                        fetching={fetching}
                                        numberForAlternatingColor={i}
                                        isPromotedHotel={false}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <Grid container justify="center" alignContent="center" className={classes.emptyCityContainer}>
                                <Typography align="center" color="textSecondary" gutterBottom variant="h2">
                                    Beklager!
                                </Typography>
                                <Typography color="textSecondary" align="center" variant="body2">
                                    Vi har dessverre ikke noen samarbeidshoteller i denne byen. Kontakt oss på
                                    info@vincu.no dersom du likevell vil ha et arrangement i denne byen
                                </Typography>
                            </Grid>
                        ))}
                </Grid>
            )}

            <Tour // The tutorial for choosing hotels. Can be extended to a tour with several steps.
                steps={tutorialSteps}
                isOpen={isTutorialOpen}
                onRequestClose={() => setIsTutorialOpen(false)}
                showNumber={false}
                showNavigation={false}
                showNavigationNumber={false}
                showButtons={false}
                disableDotsNavigation
                inViewThreshold={100}
                scrollDuration={40}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
            />
        </Grid>
    )
}

export default withStyles(styles)(PickHotelsComponent)
