import { Theme, Typography, Grid, Button } from "@material-ui/core"
import { createStyles, withStyles, WithStyles } from "@material-ui/styles"
import cn from "classnames"
import { globalStyles } from "../../styles/themes/vincu"
import React from "react"
import { Link } from "react-router-dom"
import CheckBoxSharpIcon from "@material-ui/icons/CheckBoxSharp"
import { IBookingRequestResponseData } from "../../interfaces/IBookingRequest"
import { LANDING_LAYOUT_URL } from "../../routes"

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "center",
            maxWidth: "50%",
            [theme.breakpoints.down("md")]: {
                maxWidth: "80%",
            },
            [theme.breakpoints.down("sm")]: {
                maxWidth: "100%",
            },
            margin: "auto",
            padding: theme.spacing(1),
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
                marginBottom: theme.spacing(4),
            },
            [theme.breakpoints.down("sm")]: {
                maxWidth: "80%",
            },
        },
        centerContent: globalStyles.centerContent,
        checkBox: {
            position: "absolute",
            marginLeft: theme.spacing(2),
            width: 32,
            height: 32,
            color: theme.palette.primary.light,
            [theme.breakpoints.down("xs")]: {
                position: "static",
            },
        },
        bookingReferenceBox: {
            textAlign: "center",
            padding: theme.spacing(6),
            [theme.breakpoints.down("md")]: {
                padding: theme.spacing(4),
            },
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(3),
            },
            backgroundColor: theme.palette.secondary.light,
        },
        bookingReferenceNumber: {
            padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
            backgroundColor: theme.palette.secondary.main,
            display: "inline-block",
            color: "#ffffff",
        },
        submitButton: {
            borderRadius: 0,
            padding: theme.spacing(3),
            backgroundColor: theme.palette.primary.light,
            color: "#ffffff",
        },
    })

type IProps = { bookingConfirmation: IBookingRequestResponseData }

const Confirmation: React.FunctionComponent<IProps & WithStyles<typeof styles>> = props => {
    const { classes, bookingConfirmation } = props
    return (
        <div className={cn(classes.root, classes.centerContent)}>
            <Typography variant="h1">Takk skal du ha!</Typography>
            <Typography variant="body1" align="center" gutterBottom>
                Din bestilling er nå bekreftet!
                <CheckBoxSharpIcon className={classes.checkBox} />
            </Typography>
            <Typography variant="body1" align="center">
                Hva skjer nå?
            </Typography>
            <Typography variant={"body1"} align="center">
                Du vil motta en ordrebekreftelse på mailadressen {bookingConfirmation.email} {" "}med detaljer om
                bestillingen.
            </Typography>
            <Typography variant={"body1"} align="center">
                Detaljert informasjon vil bli levert om 2-3 dager.
            </Typography>
            <Grid className={classes.bookingReferenceBox}>
                <Typography variant={"body1"}>
                    Ditt referansenummer er:{" "}
                    <span className={classes.bookingReferenceNumber}>{bookingConfirmation.id}</span>
                </Typography>
            </Grid>
            <Button
                component={Link}
                to={LANDING_LAYOUT_URL}
                variant={"contained"}
                size={"large"}
                className={classes.submitButton}
            >
                Tilbake til forsiden
            </Button>
        </div>
    )
}

export default withStyles(styles)(Confirmation)
