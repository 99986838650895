import { createMuiTheme, Theme } from "@material-ui/core"
import { StyleRules } from "@material-ui/core/styles"
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme"

const defaultTheme: Theme = createMuiTheme()

const theme: ThemeOptions = {
    palette: {
        type: "light",
        primary: {
            light: "#4e5a67",
            main: "#364045",
            contrastText: "#ffffff",
        },
        secondary: {
            light: "#c4c4c4",
            main: "#ce2221",
            dark: "#a51b1a",
        },
        text: {
            primary: "#364045",
            secondary: "#ffffff",
        },
        error: {
            main: "#ce2221",
        },
        info: {
            main: "#e3e3e3",
        },
    },
    overrides: {
        MuiDivider: {
            root: {
                height: 2,
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: 12,
            },
        },
        MuiTypography: {
            h1: {
                fontSize: 58,
                fontFamily: "Poppins",
                fontWeight: 500,
                lineHeight: 1.25,
                fontStretch: "normal",
                fontStyle: "normal",
                color: "#36404b",
                letterSpacing: "normal",
                [defaultTheme.breakpoints.down("md")]: {
                    fontSize: 52,
                },
                [defaultTheme.breakpoints.down("sm")]: {
                    fontSize: 46,
                },
                [defaultTheme.breakpoints.down("xs")]: {
                    fontSize: 40,
                },
            },
            h2: {
                fontFamily: "Poppins",
                fontSize: 36,
                fontWeight: 500,
                [defaultTheme.breakpoints.down("sm")]: {
                    fontSize: 32,
                },
                [defaultTheme.breakpoints.down("xs")]: {
                    fontSize: 26,
                },
            },
            h3: {
                fontSize: 28,
                [defaultTheme.breakpoints.down("sm")]: {
                    fontSize: 24,
                },
                [defaultTheme.breakpoints.down("xs")]: {
                    fontSize: 22,
                },
            },
            h4: {
                fontSize: 24,
                fontWeight: "bold",
                [defaultTheme.breakpoints.down("sm")]: {
                    fontSize: 24,
                },
                [defaultTheme.breakpoints.down("xs")]: {
                    fontSize: 22,
                },
                color: "#e3e3e3",
                fontFamily: "Poppins",
            },
            h5: {
                fontSize: 16,
                fontFamily: "Poppins",
                fontWeight: "bold",
                marginTop: 10,
                marginBottom: 10,
            },
            h6: {
                fontSize: 15,
                fontFamily: "Poppins",
            },

            body1: {
                fontSize: 24,
                fontFamily: "Quicksand",
                fontWeight: 500,
                fontStretch: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                [defaultTheme.breakpoints.down("sm")]: {
                    lineHeight: "1.375em",
                    fontSize: 20,
                },
                [defaultTheme.breakpoints.down("xs")]: {
                    lineHeight: "1.25em",
                },
            },
            body2: {
                fontSize: 18,
                fontFamily: "Quicksand",
                fontWeight: 500,
                fontStretch: "normal",
                lineHeight: "1.75",
                letterSpacing: "normal",
                [defaultTheme.breakpoints.up("lg")]: {
                    fontSize: 22,
                },
                [defaultTheme.breakpoints.down("sm")]: {
                    lineHeight: "1.5em",
                },
                [defaultTheme.breakpoints.down("xs")]: {
                    lineHeight: "1.375em",
                },
            },
            subtitle1: {
                fontSize: 18,
                fontFamily: "Quicksand",
                fontWeight: "bold",
                fontStretch: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                [defaultTheme.breakpoints.down("sm")]: {
                    lineHeight: "1.375em",
                },
                [defaultTheme.breakpoints.down("xs")]: {
                    lineHeight: "1.25em",
                },
            },
            subtitle2: {
                fontSize: 14,
                fontFamily: "Quicksand",
                fontWeight: "normal",
                fontStretch: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                [defaultTheme.breakpoints.down("sm")]: {
                    lineHeight: "1.375em",
                },
                [defaultTheme.breakpoints.down("xs")]: {
                    lineHeight: "1.25em",
                },
            },
        },
        MuiFab: {
            extended: {
                minWidth: 100,
                fontWeight: 600,
            },
        },
        MuiFormLabel: {
            root: {
                color: "#4e5a67",
                whiteSpace: "nowrap",
            },
        },
        MuiFormControlLabel: {
            label: {
                color: "#4e5a67",
                fontSize: 14,
                marginLeft: 0,
            },
        },
        MuiInputLabel: {
            shrink: {
                transform: `translate(0, 1.5px) scale(1)`,
                fontSize: 14,
            },
            formControl: {
                transform: "none",
            },
        },
        MuiButton: {
            root: {
                textTransform: "none",
                borderRadius: 0,
            },
            label: {
                fontSize: 24,
            },
        },
    },
}

export default theme

export const globalStyles: StyleRules = {
    centerContent: {
        display: "flex",
        justifyContent: "center",
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
}
