import { Grid, GridProps, Theme, Button } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import * as dateFns from "date-fns"
import cn from "classnames"
import React, { useState } from "react"
import { IBookingRequest } from "../../interfaces/IBookingRequest"
import { IHotel } from "../../interfaces/IHotel"

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "center",
            maxWidth: "50%",
            [theme.breakpoints.down("md")]: {
                maxWidth: "80%",
            },
            [theme.breakpoints.down("sm")]: {
                maxWidth: "100%",
            },
            margin: "auto",
            padding: theme.spacing(1),
        },
        column: {
            display: "flex",
            flexDirection: "column",
        },
        gridContainer: {
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(6),
            [theme.breakpoints.down("md")]: {
                padding: theme.spacing(3),
            },
        },
        chosenAlternative: {
            paddingTop: "0px !important",
        },
        divider: {
            backgroundColor: theme.palette.secondary.main,
            width: "100%",
        },
        //make divider ignore padding on left side
        leftDivider: {
            // marginLeft: -theme.spacing(6),
            // [theme.breakpoints.down("md")]: {
            //     marginLeft: -theme.spacing(3),
            // },
        },
        colorTextLightGray: {
            color: theme.palette.info.main,
        },
        gridCategoryContainer: {
            padding: theme.spacing(2),
        },
    })

interface ISummaryProps {
    bookingRequest: IBookingRequest
    onSubmit: () => void
}

type IProps = ISummaryProps & WithStyles<typeof styles>

const GridItem: React.FunctionComponent<GridProps> = props => {
    return <Grid xs={6} item {...props} />
}

const SummaryComponent: React.FunctionComponent<IProps> = props => {
    const { classes, bookingRequest, onSubmit } = props
    const [displayExtraMeetingRoom] = useState<boolean>(
        !!bookingRequest.numberOfMeetingRooms,
    )
    return (
        <div className={classes.root}>
            {bookingRequest ? (
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.gridContainer}
                >
                    <Grid
                        container
                        spacing={2}
                        className={cn(classes.gridCategoryContainer, classes.chosenAlternative)}
                    >
                        <Grid item xs={12}>
                            <Typography gutterBottom align="center" variant="h4">
                                Forespørsel
                            </Typography>
                        </Grid>
                        <GridItem>
                            <Typography variant={"body2"} gutterBottom color="textSecondary">
                                By/område
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                {bookingRequest.city?.name || ""}
                            </Typography>
                        </GridItem>
                        <GridItem>
                            <Typography variant={"body2"} gutterBottom color="textSecondary">
                                Antall overnattinger per døgn
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                {bookingRequest.numberOfBeds || 0}
                            </Typography>
                        </GridItem>
                        <GridItem>
                            {displayExtraMeetingRoom ? (
                                <>
                                    <Typography variant={"body2"} gutterBottom color="textSecondary">
                                        Dato
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                        {`${
                                            bookingRequest.from
                                                ? dateFns.format(bookingRequest.from, "d. MMM. yyyy")
                                                : "..."
                                        } - 
                                ${bookingRequest.to ? dateFns.format(bookingRequest.to, "d. MMM. yyyy") : "..."}`}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant={"body2"} gutterBottom color="textSecondary">
                                        Dato fra
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                        {`${
                                            bookingRequest.from
                                                ? dateFns.format(bookingRequest.from, "d. MMM. yyyy")
                                                : "..."
                                        }`}
                                    </Typography>
                                </>
                            )}
                        </GridItem>
                        <GridItem>
                            <Typography variant={"body2"} gutterBottom color="textSecondary">
                                Antall middager per dag
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                {bookingRequest.numberOfDinners || 0}
                            </Typography>
                        </GridItem>
                        {!displayExtraMeetingRoom && (
                            <GridItem>
                                <Typography variant={"body2"} gutterBottom color="textSecondary">
                                    Dato til
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                    {`${bookingRequest.to ? dateFns.format(bookingRequest.to, "d. MMM. yyyy") : "..."}`}
                                </Typography>
                            </GridItem>
                        )}
                        <GridItem>
                            <Typography variant={"body2"} gutterBottom color="textSecondary">
                                Antall gjester
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                {bookingRequest.numberOfGuests || 0}
                            </Typography>
                        </GridItem>
                        {displayExtraMeetingRoom && (
                            <GridItem>
                                <Typography variant={"body2"} gutterBottom color="textSecondary">
                                    Antall møterom
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                    {bookingRequest.numberOfMeetingRooms || 0}
                                </Typography>
                            </GridItem>
                        )}
                        <Divider className={cn(classes.divider, classes.leftDivider)} />
                    </Grid>
                    <Grid container spacing={2} className={classes.gridCategoryContainer}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h4" align="center">
                                Kontaktinformasjon
                            </Typography>
                        </Grid>
                        <GridItem>
                            <Typography variant={"body2"} gutterBottom color="textSecondary">
                                Navn
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                {bookingRequest.fullName}
                            </Typography>
                        </GridItem>
                        <GridItem>
                            <Typography variant={"body2"} gutterBottom color="textSecondary">
                                Firma
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                {bookingRequest.company}
                            </Typography>
                        </GridItem>
                        <GridItem xs={12}>
                            <Typography variant={"body2"} gutterBottom color="textSecondary">
                                E-post
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                {bookingRequest.email}
                            </Typography>
                        </GridItem>
                        <GridItem>
                            <Typography variant={"body2"} gutterBottom color="textSecondary">
                                Telefonnummer
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                {bookingRequest.phone}
                            </Typography>
                        </GridItem>
                        {bookingRequest.reference && (
                            <GridItem>
                                <Typography variant={"body2"} gutterBottom color="textSecondary">
                                    Hvor hørte du om oss?
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                    {bookingRequest.reference}
                                </Typography>
                            </GridItem>
                        )}
                        {bookingRequest.comment && (
                            <GridItem>
                                <Typography variant={"body2"} gutterBottom color="textSecondary">
                                    Kommentar
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" className={classes.colorTextLightGray}>
                                    {bookingRequest.comment}
                                </Typography>
                            </GridItem>
                        )}
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid container spacing={2} className={classes.gridCategoryContainer}>
                        <Grid item xs={12}>
                            <Typography align="center" variant="h4">
                                Foretrukne hoteller
                            </Typography>
                        </Grid>
                        {bookingRequest.hotels &&
                            bookingRequest.hotels.map((hotel: IHotel, i) => {
                                return (
                                    <GridItem key={i}>
                                        <Typography color="textSecondary" variant="subtitle2">
                                            {hotel.city}
                                        </Typography>
                                        <Typography className={classes.colorTextLightGray} variant="subtitle1">
                                            {hotel.name}
                                        </Typography>
                                    </GridItem>
                                )
                            })}
                    </Grid>
                    <Grid item>
                        <Button color="secondary" variant="contained" size="large" onClick={onSubmit}>
                            Neste
                        </Button>
                    </Grid>
                </Grid>
            ) : null}
        </div>
    )
}

export default withStyles(styles)(SummaryComponent)
