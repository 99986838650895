import React from "react"
import StatementComponent from "./Statement"

export type IProps = {
    header: string,
    subHeader?: string,
    text: string,
    image?: string,
    align: "left" | "right",
}


class Statement extends React.Component<IProps> {
    public render() {
        return <StatementComponent {...this.props} />
    }
}

export default Statement