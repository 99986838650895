import React from "react"
import LandingComponent from "./Landing"
import { RouteComponentProps } from "react-router"

interface IProps extends RouteComponentProps {}

class Landing extends React.Component<IProps, {}> {
    public render() {
        
        return <LandingComponent {...this.props} styreWeb={false} />
    }
}

export default Landing