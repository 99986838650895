import isEqual from "lodash/isEqual"
import React, { useState } from "react"
import { connect } from "react-redux"
import { IBookingRequest } from "../../interfaces/IBookingRequest"
import { IHotel } from "../../interfaces/IHotel"
import { IIsHotelSelected, IToggleHotel } from "../../interfaces/IPickHotelsView"
import { IReduxState } from "../../store"
import { addHotel, IAddHotelAction, IRemoveHotelAction, removeHotel } from "../../store/actions/booking"
import MapMarkerComponent from "./MapMarker"

export interface IMapMarkerProps {
    hotel: IHotel
    index: number
    latitude: number
    longitude: number
    allPopups: {}
    setShowAllPopups: (showPopup: {}) => void
}

interface IMapStateToProps {
    bookingRequest: IBookingRequest
}

interface IMapDispatchToProps {
    addHotel: IAddHotelAction
    removeHotel: IRemoveHotelAction
}

type IProps = IMapMarkerProps & IMapStateToProps & IMapDispatchToProps

const MapMarker: React.FunctionComponent<IProps> = props => {
    const { index, hotel, bookingRequest, latitude, longitude, allPopups, setShowAllPopups } = props

    const [isSnackbarOpen, setSnackbarOpen] = useState<boolean>(false)

    const isHotelSelected: IIsHotelSelected = () => {
        const hotels = bookingRequest.hotels
        return !!hotels && hotels.findIndex(hotel => isEqual(hotel, props.hotel)) >= 0
    }

    const toggleHotel: IToggleHotel = (hotel: IHotel, checked) => {
        if (!checked) {
            props.removeHotel(hotel)
        } else {
            if (bookingRequest && bookingRequest.hotels && bookingRequest.hotels.length + 1 > 5) {
                setSnackbarOpen(true)
            } else {
                props.addHotel(hotel)
            }
        }
    }

    return (
        <div>
            <MapMarkerComponent
                index={index}
                hotel={hotel}
                latitude={latitude}
                longitude={longitude}
                allPopups={allPopups}
                setShowAllPopups={setShowAllPopups}
                selected={isHotelSelected()}
                toggleHotel={toggleHotel}
                isSnackbarOpen={isSnackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
            />
        </div>
    )
}

const mapStateToProps = (state: IReduxState): IMapStateToProps => {
    return {
        bookingRequest: state.bookingRequest.data,
    }
}

export default connect<IMapStateToProps, IMapDispatchToProps, IMapMarkerProps, IReduxState>(mapStateToProps, {
    addHotel,
    removeHotel,
})(MapMarker)
