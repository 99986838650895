export const FIND_ALL_HOTELS = "FIND_ALL_HOTELS"
export const FIND_ALL_HOTELS_PENDING = "FIND_ALL_HOTELS_PENDING"
export const FIND_ALL_HOTELS_REJECTED = "FIND_ALL_HOTELS_REJECTED"
export const FIND_ALL_HOTELS_FULFILLED = "FIND_ALL_HOTELS_FULFILLED"

export const GET_PROMOTED_HOTELS = "GET_PROMOTED_HOTELS"
export const GET_PROMOTED_HOTELS_PENDING = "GET_PROMOTED_HOTELS_PENDING"
export const GET_PROMOTED_HOTELS_REJECTED = "GET_PROMOTED_HOTELS_REJECTED"
export const GET_PROMOTED_HOTELS_FULFILLED = "GET_PROMOTED_HOTELS_FULFILLED"

export const GET_CITIES = "GET_CITIES"
export const GET_CITIES_PENDING = "GET_CITIES_PENDING"
export const GET_CITIES_REJECTED = "GET_CITIES_REJECTED"
export const GET_CITIES_FULFILLED = "GET_CITIES_FULFILLED"

export const GET_EVENT_TYPES = "GET_EVENT_TYPES"
export const GET_EVENT_TYPES_PENDING = "GET_EVENT_TYPES_PENDING"
export const GET_EVENT_TYPES_REJECTED = "GET_EVENT_TYPES_REJECTED"
export const GET_EVENT_TYPES_FULFILLED = "GET_EVENT_TYPES_FULFILLED"

export const CHANGE_BOOKING_REQUEST = "CHANGE_BOOKING_REQUEST"
export const SET_TOUCHED = "SET_TOUCHED"
export const ADD_HOTEL_TO_BOOKING_REQUEST = "ADD_HOTEL_TO_BOOKING_REQUEST"
export const REMOVE_HOTEL_FROM_BOOKING_REQUEST = "REMOVE_HOTEL_FROM_BOOKING_REQUEST"
export const RESET_BOOKING_REQUEST_STATE = "RESET_BOOKING_REQUEST_STATE"

export const ADD_CITIES_FROM_COUNTY = "ADD_CITIES_FROM_COUNTY"
export const ADD_CITIES_FROM_COUNTY_FULFILLED = "ADD_CITIES_FROM_COUNTY_FULFILLED"

export const contactInfoTypes = {
    UPDATE_CONTACT_INFO: "UPDATE_CONTACT_INFO",
}

export const SET_STYLE_TYPE = "SET_STYLE_TYPE"
