import { IGetHotelsActionReturn } from "../actions/hotels"
import { FIND_ALL_HOTELS_FULFILLED, FIND_ALL_HOTELS_PENDING, FIND_ALL_HOTELS_REJECTED } from "../types"
import { ReduxState } from "./index"

export default (state: ReduxState["hotels"], action: IGetHotelsActionReturn) => {
    switch (action.type) {
        case FIND_ALL_HOTELS_PENDING:
            return {
                ...state,
                fetching: true,
                messages: [],
            }
        case FIND_ALL_HOTELS_FULFILLED:
            return {
                ...state,
                fetching: false,
                data: action.payload,
                messages: action.payload.messages,
            }
        case FIND_ALL_HOTELS_REJECTED:
            return {
                ...state,
                fetching: false,
                messages: action.payload.messages,
            }
        default:
            return state || null
    }
}
