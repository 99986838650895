import React from "react"
import { Theme, withStyles } from "@material-ui/core/styles"
import { Typography, Grid } from "@material-ui/core"
import { createStyles, WithStyles } from "@material-ui/styles"
import PersonIcon from '@material-ui/icons/Person';
import cn from "classnames"


const styles = (theme: Theme) =>
    createStyles({
        rootGrid: {
            justifyContent: "flex-end",
            [theme.breakpoints.down("sm")]: {
                justifyContent: "center",
            },
        },
        speechBubble: {
            width: "560px",
            position: "relative",
            padding: theme.spacing(3),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),

            [theme.breakpoints.down("md")]: { width: "500px" },

            [theme.breakpoints.down("sm")]: { width: "100%" },

            '&:before': {
                content: '""',
                position: "absolute",
                width: "0",
                height: "0",
                bottom: "100%",
                top: "32px",
                border: "1.2rem solid transparent",
            }
        },
        right: {
            color: theme.palette.text.primary,
            background: theme.palette.info.main,
            textAlign: "left",
            '&:before': {
                left: "-18px",
                borderLeft: "none",
                borderRightColor: theme.palette.info.main,

                [theme.breakpoints.down("sm")]: {
                    bottom: "100%",
                    top: "-18px",
                    left: "50%",
                    transform: "translate(-50%, 0%)",
                    borderTop: "none",
                    borderBottomColor: theme.palette.info.main,
                    border: "1.2rem solid transparent",
                },
            },
        },
        left: {
            '&:before': {
                right: "-18px",
                bottom: "100%",
                top: "-18px",
                left: "50%",
                transform: "translate(-50%, 0)",
                borderTop: "none",
                borderBottomColor: theme.palette.primary.main,
                border: "1.2rem solid transparent",
            },

            // If vertical speechbuble with image above (on small screens) then align all text left.
            [theme.breakpoints.down("sm")]: { textAlign: "left" },
        },
        image: {
            maxWidth: "150px",
            maxHeight: "100px",
        },
        imageLeft: {
            marginRight: theme.spacing(4),

            [theme.breakpoints.down("md")]: { marginRight: theme.spacing(1) },
            [theme.breakpoints.down("sm")]: { margin: "0" },
        },
        imageRight: {
            marginLeft: theme.spacing(4),

            [theme.breakpoints.down("md")]: { marginLeft: theme.spacing(1) },
            [theme.breakpoints.down("sm")]: { margin: "0" },
        },
        textContainer: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down("sm")]: { marginBottom: 0 },
        },
        iconContainer: {
            marginTop: "-16px",
            [theme.breakpoints.down("sm")]: { margin: "0" },

            "& > *": {
                fontSize: 130,
                [theme.breakpoints.down("sm")]: { fontSize: 100 },
            },
        },
        iconContainerRight: {
            color: theme.palette.info.main,
            
        },
        iconContainerLeft: {
            color: theme.palette.primary.main,
        },
        speechBubbleHeader: {
            fontFamily: "Poppins",
            fontSize: 23,
            fontWeight: 500,
        }
    })


type IProps = {
    header: string,
    subHeader?: string,
    text: string,
    image?: string,
    align: "left" | "right",
}


const StatementComponent: React.FunctionComponent<IProps & WithStyles<typeof styles>> = props => {
    const { classes, header, subHeader, text, image, align } = props

    return (
        <Grid   
            container 
            direction={align === "right" ? "row" : "row-reverse"} 
            alignItems="flex-start" 
            spacing={4}
            className={classes.rootGrid}
        >
            <Grid item>
                {image !== undefined ? (
                    <img
                        alt={header}
                        className={cn(classes.image, align === "left" ? classes.imageRight : classes.imageLeft)}
                        src={image}
                    />
                ) : (
                    <div className={cn(classes.iconContainer, align === "left" ? classes.iconContainerLeft : classes.iconContainerRight)}>
                        <PersonIcon />
                    </div>
                )}
            </Grid>
            <Grid item md="auto" sm={12}>
                <div className={cn(
                    classes.speechBubble,  
                    align === "left" ? classes.left : classes.right
                    )}
                >
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <div className={classes.speechBubbleHeader}>{header}</div>
                        </Grid>
                        {subHeader && subHeader.length > 0 && ( // Add subHeader if provided
                            <Grid item>
                                <Typography variant="subtitle1">{subHeader}</Typography>
                            </Grid>
                        )}
                        <Grid item className={classes.textContainer}>
                            <Typography variant="body2">{'"' + text + '"'}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(StatementComponent)