import { combineReducers, Reducer } from "redux"
import { createTransform, persistReducer } from "redux-persist"
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1"
import { PersistConfig } from "redux-persist/es/types"
import storage from "redux-persist/lib/storage"
import { IBookingRequestState } from "../index"
import bookingRequestReducer from "./bookingRequestReducer"
import customizationReducer from "./customizationReducer"
import hotelsReducer from "./hotelsReducer"
import promotedHotelsReducer from "./promotedHotelsReducer"
import citiesReducer from "./citiesReducer"
import eventTypesReducer from "./eventTypesReducer"

const dateTransform = createTransform<IBookingRequestState, IBookingRequestState>(
    inboundState => {
        return { ...inboundState }
    },
    // transform state being rehydrated
    (outboundState, key) => {
        switch (key) {
            case "bookingRequest":
                return {
                    ...outboundState,
                    data: {
                        ...outboundState.data,
                        hotels: [],
                        from: outboundState.data.from ? new Date(outboundState.data.from as Date) : undefined,
                        to: outboundState.data.to ? new Date(outboundState.data.to as Date) : undefined,
                        styreWeb: false,
                    },
                }
            default:
                return { ...outboundState }
        }
    },
    // define which reducers this transform gets called for.
    { whitelist: ["bookingRequest"] },
)

const persistConfig: PersistConfig<ReduxState> = {
    key: "VINCU_PERSISTENT_STORE",
    storage,
    stateReconciler: autoMergeLevel1,
    whitelist: ["customization", "bookingRequest"],
    transforms: [dateTransform],
}

const combinedReducers: any = combineReducers({
    hotels: hotelsReducer,
    promotedHotels: promotedHotelsReducer,
    cities: citiesReducer,
    eventTypes: eventTypesReducer,
    bookingRequest: bookingRequestReducer,
    customization: customizationReducer,
})

const persistedReducers: Reducer = persistReducer(persistConfig, combinedReducers)

export default persistedReducers

export type ReduxState = ReturnType<typeof combinedReducers>
