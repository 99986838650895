import { SET_STYLE_TYPE } from "../types"

export default (state, action) => {
    switch (action.type) {
        case SET_STYLE_TYPE:
            return {
                ...state,
                styleType: action.payload,
            }
        default:
            return state || null
    }
}
