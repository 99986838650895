import _ from "lodash"
import React from "react"
import "./App.css"
import Footer from "./components/Footer"
import Header from "./components/Header"
import BookingLayout from "./layouts/Booking"
import LandingLayout from "./layouts/Landing"
import StyreWebLandingLayout from "./layouts/StyreWebLanding"
import ErrorLayout from "./views/Error"
import AboutUs from "./views/AboutUs"
import Referals from "./views/Referals"
import { BOOKING_LAYOUT_URL, LANDING_LAYOUT_URL, ERROR_LAYOUT_URL, ABOUT_US_LAYOUT_URL, REFERALS_LAYOUT_URL, STYREWEB_LANDING_LAYOUT_URL } from "./routes"


// axios
import Axios from "./utils/Axios"

// redux
import { connect } from "react-redux"

// React router
import { Redirect, Route, Switch } from "react-router-dom"

// Date-picker
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import * as dateFns from "date-fns"
import norwegianLocale from "date-fns/locale/nb"

// Material UI
import { CssBaseline } from "@material-ui/core"
import { createMuiTheme, makeStyles } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import { ReduxState } from "./store/reducers"
import theme from "./styles/themes/vincu"

const axios = new Axios()
axios.init()

class PickerUtils extends DateFnsUtils {
    public static getDatePickerHeaderText(date: Date) {
        return _.upperFirst(dateFns.format(date, "d. MMM YYYY"))
    }

    public static getCalendarHeaderText(date: Date): string {
        return dateFns.format(date, "MMMM YYYY")
    }
}

const createTheme = (customization: IProps["customization"]) => {
    return createMuiTheme({
        ...theme,
        palette: {
            ...theme.palette,
        },
    })
}

export interface IProps {
    customization: ReduxState["customization"]
}

const useStyles = makeStyles(() => ({
    root: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "#ffffff",
    },
}))

const App: React.FunctionComponent<IProps> = props => {
    const { customization } = props
    const classes = useStyles()
    return (
        <MuiPickersUtilsProvider utils={PickerUtils} locale={norwegianLocale}>
            <ThemeProvider theme={createTheme(customization)}>
                <CssBaseline>
                    <div className={classes.root}>
                        <Header />
                        <Switch>
                            <Route exact path={LANDING_LAYOUT_URL} component={LandingLayout} />
                            <Route path={STYREWEB_LANDING_LAYOUT_URL} component={StyreWebLandingLayout} />
                            <Route path={BOOKING_LAYOUT_URL} component={BookingLayout} />
                            <Route path={ERROR_LAYOUT_URL} component={ErrorLayout} />
                            <Route path={ABOUT_US_LAYOUT_URL} component={AboutUs} />
                            <Route path={REFERALS_LAYOUT_URL} component={Referals} />
                            <Redirect to="/" />
                        </Switch>
                        <Footer />
                    </div>
                </CssBaseline>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}

const mapStateToProps = (state: ReduxState) => ({
    customization: state.customization,
})

export default connect(mapStateToProps)(App)
