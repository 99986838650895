import React from "react"
import { RouteComponentProps } from "react-router"
import AboutUsComponent from "./AboutUs"

type IProps = RouteComponentProps

class AboutUs extends React.Component<IProps, {}> {
    public render() {
        return <AboutUsComponent />
    }
}

export default AboutUs
