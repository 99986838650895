import React, { useState } from "react"
import { Theme, Grid } from "@material-ui/core"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import { IHotel } from "../../interfaces/IHotel"
import ReactMapGL from "react-map-gl"
import MapMarker from "../MapMarker"
import mapValues from "lodash/mapValues"

const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: "3vh",
            marginBottom: "10vh",
        },
    })

type IProps = WithStyles<typeof styles> & { hotels: IHotel[] }

const MapBoxComponent: React.FunctionComponent<IProps> = props => {
    const { classes, hotels } = props

    const [viewport, setViewport] = useState({
        width: "100vw",
        height: "60vh",
        latitude: hotels[0].latitude,
        longitude: hotels[0].longitude,
        zoom: 13,
    })

    // Controls all popups
    const [allPopups, setShowAllPopups] = useState<any>({})

    const handleShowAllPopups = index => {
        let closedAllPopups = mapValues(allPopups, () => false)
        if (index == -1) {
            // Close all popups
            setShowAllPopups({ ...closedAllPopups })
        }

        if (allPopups[index]) {
            setShowAllPopups({ ...closedAllPopups, [index]: !allPopups[index] })
        } else {
            return setShowAllPopups({ ...closedAllPopups, [index]: true })
        }
    }
    return (
        <Grid className={classes.root} container direction="column" justify="center" alignItems="center">
            <Grid item container xs={12} sm={12} md={12} lg={12}>
                <ReactMapGL
                    {...viewport}
                    mapStyle="mapbox://styles/spireconsulting/ckewobxzb0dez19pb18f03svv"
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                    onViewportChange={setViewport}
                >
                    {hotels.map((hotel, index) => {
                        return (
                            <MapMarker
                                key={index}
                                hotel={hotel}
                                index={index}
                                latitude={hotel.latitude}
                                longitude={hotel.longitude}
                                allPopups={allPopups}
                                setShowAllPopups={handleShowAllPopups}
                            />
                        )
                    })}
                </ReactMapGL>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(MapBoxComponent)
