import React from "react"
import { Theme, withStyles } from "@material-ui/core/styles"
import { createStyles, WithStyles } from "@material-ui/styles"
import { Grid } from "@material-ui/core"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as filledCircle, faAdjust as halfCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle as emptyCircle } from '@fortawesome/free-regular-svg-icons'
const tripAdvisorLogo = require("../../assets/img/vincu/tripadvisorlogo.svg")

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            margin: theme.spacing(1, 0),
        },
        logo: {
            height: "44px",
        },
    })

type IProps = {
    rating: number, // 0 <= number < 10.
    ratingColor?: string,
}

type starFill = "empty" | "half" | "filled";

const RatingComponent: React.FunctionComponent<IProps & WithStyles<typeof styles>> = props => {
    const { classes, rating, ratingColor } = props

    const getStarArray = (rating: number): starFill[] => {
        let stars: starFill[] = [];

        for (let i: number = 1; i <= 5; i += 1) {
            if (rating - i >= -0.25) {
                stars.push("filled");
                continue;
            }
            if (rating - i >= -0.75) {
                stars.push("half");
                continue;
            }
            stars.push("empty");
        }
        return stars;
    }

    return (
            <Grid container item direction="row" justify="flex-start" alignItems="center" spacing={1} className={classes.root}>
                <Grid item>
                    <img
                        alt={"TripAdvisor"}
                        className={classes.logo}
                        src={tripAdvisorLogo}
                    />
                </Grid>  
                {
                    getStarArray(rating).map((starFill, index) => {
                        return (
                            <Grid key={index} item>
                                {
                                    starFill === "filled" ? 
                                    <FontAwesomeIcon icon={filledCircle} color={ratingColor || undefined} size="lg"/> 
                                    :
                                    starFill === "half" ? 
                                    <FontAwesomeIcon icon={halfCircle} color={ratingColor || undefined} size="lg"/> 
                                    :
                                    <FontAwesomeIcon icon={emptyCircle} color={ratingColor || undefined} size="lg"/> 
                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
    )
}

export default withStyles(styles)(RatingComponent)
