import { Theme } from "@material-ui/core"
import { createStyles, withStyles, WithStyles } from "@material-ui/styles"
import Landing from "../../views/Landing/Landing"
import React, { useEffect } from "react"
import { RouteComponentProps } from "react-router"
import queryString from "query-string"
import { IChangeBookingRequestAction } from "../../store/actions/booking"

const styles = (theme: Theme) => {
    return createStyles({
        root: {},
    })
}

type IProps = WithStyles<typeof styles> &
    RouteComponentProps & {
        changeBookingRequest: IChangeBookingRequestAction
    }
//Styrewebs own customized landingsite
const LandingComponent: React.FunctionComponent<IProps> = props => {
    const { classes, history, changeBookingRequest } = props
    //takes data from styrewebusers from the URL and fills in the bookingrequest with it
    const { firstName, lastName, email, phone, company } = queryString.parse(history.location.search)
    useEffect(() => {
        changeBookingRequest({ reference: "StyreWeb", styreWeb: true })
        if (typeof company === "string") {
            changeBookingRequest({ company: company })
        }
        if (typeof firstName === "string" && typeof lastName === "string") {
            changeBookingRequest({ fullName: firstName + " " + lastName })
        }
        if (typeof email === "string") {
            changeBookingRequest({ email: email })
        }
        if (typeof phone === "string") {
            //Removes spaces from number
            changeBookingRequest({ phone: "+47" + phone.replace(/\s/g, '') })
        }
    }, [])
    return (
        <div className={classes.root}>
            <Landing {...props} styreWeb={true} ></Landing>
        </div>
    )
}

export default withStyles(styles)(LandingComponent)
