import React from "react"
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { compose } from "redux"
import { formFields, IFormValues } from "../../components/Filters"
import { CONTACT_INFORMATION_VIEW_URL } from "../../routes"
import { IBookingRequestState, IHotelsState, IPromotedHotelsState, IReduxState } from "../../store"
import {
    addHotel,
    IAddHotelAction,
    IChangeBookingRequestAction,
    IRemoveHotelAction,
    removeHotel,
} from "../../store/actions/booking"
import { getPromotedHotels, IGetPromotedHotelsAction } from "../../store/actions/promotedHotels"
import { getHotels, IGetHotelsAction } from "../../store/actions/hotels"
import { pick } from "../../utils/object"
import { IErrors } from "../../validators/bookingRequest.validator"
import PickHotelsComponent from "./PickHotels"

interface IMapStateToProps {
    bookingRequestState: IBookingRequestState
    hotelsState: IHotelsState
    promotedHotelsState: IPromotedHotelsState
    errors: IErrors<IFormValues | "hotels">
}

interface IMapDispatchToProps {
    getHotels: IGetHotelsAction
    getPromotedHotels: IGetPromotedHotelsAction
    changeBookingRequest: IChangeBookingRequestAction
    removeHotel: IRemoveHotelAction
    addHotel: IAddHotelAction
}

export interface IState {}

type IProps = IMapStateToProps & IMapDispatchToProps & RouteComponentProps

class PickHotels extends React.Component<IProps, IState> {
    public componentDidMount(): void {
        this.props.getHotels()
        //Make Rockheim Panorama a promoted hotel
        this.props.getPromotedHotels(269)
    }
    
    public isFormValid = (): boolean => {
        return [...formFields, "hotels"].every(key => {
            return !this.props.errors[key]
        })
    }

    public onSubmit = (): void => {
        this.props.history.push(CONTACT_INFORMATION_VIEW_URL)
    }
    public render() {
        return (
            <PickHotelsComponent
                onSubmit={this.onSubmit}
                isValid={this.isFormValid}
                hotelsState={this.props.hotelsState}
                bookingRequest={this.props.bookingRequestState.data}
                promotedHotelsState={this.props.promotedHotelsState}
            />
        )
    }
}

const mapStateToProps = (state: IReduxState): IMapStateToProps => {
    return {
        hotelsState: state.hotels,
        promotedHotelsState: state.promotedHotels,
        bookingRequestState: state.bookingRequest,
        errors: pick<IErrors<IFormValues | "hotels">>(state.bookingRequest.errors, [...formFields, "hotels"]),
    }
}

export default compose(
    connect(mapStateToProps, { getHotels, getPromotedHotels, addHotel, removeHotel }),
    withRouter,
)(PickHotels) as React.ComponentType
