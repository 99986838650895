import React from "react"
import AboutVincuComponent from "./AboutVincu"

class AboutVincu extends React.Component {
    public render() {
        return <AboutVincuComponent {...this.props} />
    }
}

export default AboutVincu
