import React from "react"
import { IOnChange } from "../types"
import PhoneFieldComponent from "./PhoneField"

export interface IProps {
    name: string
    onChange: IOnChange
    value?: string
    defaultCountry?: string
    label: string
    error?: string
    required?: boolean
    onTouched?: () => void
}

class PhoneField extends React.Component<IProps> {
    public render() {
        return <PhoneFieldComponent {...this.props} />
    }
}

export default PhoneField
