import React from "react"
import { RouteComponentProps, withRouter } from "react-router"
import { compose } from "redux"
import { connect } from "react-redux"
import { IReduxState } from "../../store"
import { changeBookingRequest, IChangeBookingRequestAction } from "../../store/actions/booking"
import StyreWebLandingComponent from "./StyreWebLanding"
import { IOnChange } from "../../components/Fields/types"

interface IMapDispatchToProps {
    changeBookingRequest: IChangeBookingRequestAction
}

interface IProps extends RouteComponentProps {}

class StyreWebLanding extends React.Component<IProps & IMapDispatchToProps, {}> {
    public onChange: IOnChange = (fieldName, value) => {
        this.props.changeBookingRequest({ [fieldName]: value })
    }
    public render() {
        return <StyreWebLandingComponent {...this.props} />
    }
}

export default compose(
    connect<{}, IMapDispatchToProps, {}, IReduxState>(null, { changeBookingRequest }),
    withRouter,
)(StyreWebLanding) as React.ComponentType
