import { IGetPromotedHotelsActionReturn } from "../actions/promotedHotels"
import { GET_PROMOTED_HOTELS_FULFILLED, GET_PROMOTED_HOTELS_PENDING, GET_PROMOTED_HOTELS_REJECTED } from "../types"
import { ReduxState } from "./index"

export default (state: ReduxState["promotedHotels"], action: IGetPromotedHotelsActionReturn) => {
    switch (action.type) {
        case GET_PROMOTED_HOTELS_PENDING:
            return {
                ...state,
                fetching: true,
                messages: [],
            }
        case GET_PROMOTED_HOTELS_FULFILLED:
            return {
                ...state,
                fetching: false,
                data: action.payload,
                messages: action.payload.messages,
            }
        case GET_PROMOTED_HOTELS_REJECTED:
            return {
                ...state,
                fetching: false,
                messages: action.payload.messages,
            }
        default:
            return state || null
    }
}
