import { IGetCitiesActionReturn } from "../actions/cities"
import { GET_CITIES_FULFILLED, GET_CITIES_PENDING, GET_CITIES_REJECTED } from "../types"
import { ReduxState } from "./index"

export default (state: ReduxState["cities"], action: IGetCitiesActionReturn) => {
    switch (action.type) {
        case GET_CITIES_PENDING:
            return {
                ...state,
                fetching: true,
                messages: [],
            }
        case GET_CITIES_FULFILLED:
            return {
                ...state,
                fetching: false,
                data: action.payload.flat(),
                messages: action.payload.messages,
            }
        case GET_CITIES_REJECTED:
            return {
                ...state,
                fetching: false,
                messages: action.payload.messages,
            }
        default:
            return state || null
    }
}
