import { Theme, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import { withStyles } from "@material-ui/core/styles"
import { createStyles, WithStyles } from "@material-ui/styles"
import cn from "classnames"
import React from "react"
import { RouteComponentProps } from "react-router"
import vincuLogo from "../../assets/img/vincu/vincu_logo_small.png"
import Hidden from "@material-ui/core/Hidden"
import Stepper from "../../components/Stepper"
import StyreWebHeaderComponent from "./StyreWebHeaderComponent"
import {
    LANDING_LAYOUT_URL,
    CONFIRMATION_VIEW_URL,
    CONTACT_INFORMATION_VIEW_URL,
    FILTER_VIEW_URL,
    SUMMARY_VIEW_URL,
    ABOUT_US_VIEW_PATH,
    REFERALS_VIEW_PATH,
    STYREWEB_LANDING_LAYOUT_URL
} from "../../routes"
import DropdownMenu from "../../components/DropdownMenu"
import ContactDropdown from "../../components/ContactDropdown"
import { IBookingRequest } from "../../interfaces/IBookingRequest"


const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: "absolute",
            zIndex: 2,
            padding: `${theme.spacing(3)}px ${theme.spacing(20)}px 0px`,
            [theme.breakpoints.down("md")]: {
                padding: `${theme.spacing(3)}px ${theme.spacing(12)}px 0px`,
            },
            [theme.breakpoints.down("sm")]: {
                padding: `${theme.spacing(2)}px ${theme.spacing(6)}px 0px`,
            },
            [theme.breakpoints.down("xs")]: {
                padding: `${theme.spacing(1)}px ${theme.spacing(3)}px 0px`,
            },
        },
        logo: {
            height: 120,
            width: 90,
            [theme.breakpoints.down("md")]: {
                height: 100,
                width: 72,
            },
            [theme.breakpoints.down("sm")]: {
                height: 80,
                width: 58,
            },
        },
        icon: {
            height: 25,
            width: 25,
            marginRight: theme.spacing(2),
        },
        contactInfoWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        textWrapper: {
            display: "flex",
            flexDirection: "row",
            marginLeft: theme.spacing(2),
            marginBottom: 2,
        },
        headerElement: {
            height: "fit-content",
            "&:hover": {
                borderBottom: "2px solid black",
                marginBottom: -2,
            },
        },
        dropdownMenu: {
            display: "flex",
            flexGrow: 0,
            flexFlow: "row-reverse",
        },
        navbarText: {
            fontSize: 20,
            fontWeight: 500,
            [theme.breakpoints.down("sm")]: {
                fontSize: 20,
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: 18,
            },
            fontFamily: "Poppins",
        }
    })

type IProps = WithStyles<typeof styles> & RouteComponentProps & { bookingRequest: IBookingRequest }

const getActiveStepFromPath = (path: string) => {
    switch (path) {
        case FILTER_VIEW_URL:
            return 0
        case CONTACT_INFORMATION_VIEW_URL:
            return 1
        case SUMMARY_VIEW_URL:
            return 2
        case CONFIRMATION_VIEW_URL:
            return 3
        case STYREWEB_LANDING_LAYOUT_URL:
            return 4
    }
}

//Hide stepper on landingpage
const showStepper = (path: string): boolean => {
    return path === FILTER_VIEW_URL || path === CONTACT_INFORMATION_VIEW_URL || path === CONFIRMATION_VIEW_URL || path === SUMMARY_VIEW_URL
}

//Checks if styrewebheader or normal header should be displased
const showStyreWebComponent = (path: string): boolean => {
    return path === STYREWEB_LANDING_LAYOUT_URL
}

const steps: Array<{ label: string; href: string }> = [
    { label: "Finn hoteller", href: FILTER_VIEW_URL },
    { label: "Informasjon", href: CONTACT_INFORMATION_VIEW_URL },
    { label: "Send forespørsel", href: SUMMARY_VIEW_URL },
]

const HeaderComponent: React.FunctionComponent<IProps> = props => {
    const { classes, history, bookingRequest } = props
    const path = history.location.pathname
    const activeStep = getActiveStepFromPath(path)
    return (
        <Grid container direction="row" justify="space-between" className={cn(classes.root)}>
            <Grid item>
                {showStyreWebComponent(path) ? (
                    <StyreWebHeaderComponent bookingRequest={bookingRequest} />
                ) : (
                    <Link underline={"none"} href={LANDING_LAYOUT_URL}>
                        <img className={classes.logo} src={vincuLogo} alt={"Vincu"} />
                    </Link>
                )}
            </Grid>
            <Hidden mdUp>
                <Grid item xs={8} className={classes.dropdownMenu}>
                    <DropdownMenu />
                </Grid>
            </Hidden>
            <Grid container item md direction="row" justify="flex-end">
                <Hidden smDown>
                    <Grid item xs sm={7} md={6} lg={5} className={classes.contactInfoWrapper}>
                        <Grid item className={classes.textWrapper}>
                            <Link className={classes.headerElement} underline={"none"} href={ABOUT_US_VIEW_PATH}>
                                <Typography noWrap className={classes.navbarText}>
                                    Om oss
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item className={classes.textWrapper}>
                            <Link className={classes.headerElement} underline={"none"} href={REFERALS_VIEW_PATH}>
                                <Typography noWrap className={classes.navbarText}>
                                    Referanser
                                </Typography>
                            </Link>
                        </Grid>
                        <ContactDropdown />
                    </Grid>
                </Hidden>
                {showStepper(path) && (
                    <Grid item xs={12} sm={12} lg={12}>
                        <Stepper steps={steps} activeStep={activeStep} />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(HeaderComponent)
