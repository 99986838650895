import { Theme } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import FilledInput from "@material-ui/core/FilledInput"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import { withStyles } from "@material-ui/core/styles"
import { WithStyles } from "@material-ui/styles"
import React from "react"
import { IProps as ParentProps } from "./"

const styles = (theme: Theme) => ({
    root: {},
})

type IProps = ParentProps & WithStyles<typeof styles>
let idCount: number = 0
const TextAreaComponent: React.FunctionComponent<IProps> = props => {
    const {
        classes,
        onChange,
        value,
        error,
        name,
        onTouched,
        label,
        required,
        multiline,
        variant,
        type,
        fullWidth,
        rows,
        placeholder,
        InputProps,
    } = props
    const id = `TextField-${name}-${idCount++}`
    return (
        <FormControl variant={variant} fullWidth={fullWidth}>
            <InputLabel shrink={true} htmlFor={id}>
                {label}
            </InputLabel>
            <FilledInput
                id={id}
                className={classes.root}
                onBlur={onTouched}
                name={name}
                value={value}
                type={type}
                rows={rows}
                placeholder={placeholder}
                disableUnderline={true}
                required={required}
                multiline={multiline}
                onChange={e => onChange(name, type === "number" ? parseInt(e.target.value, 10) : e.target.value)}
                {...InputProps}
            />
            <FormHelperText>{!!error ? error : null}</FormHelperText>
        </FormControl>
    )
}

export default withStyles(styles)(TextAreaComponent)
