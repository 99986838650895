import { Checkbox, Theme, Typography, Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { createStyles, withStyles, WithStyles } from "@material-ui/styles"
import React, { useState } from "react"
import { IToggleHotel } from "../../interfaces/IPickHotelsView"
import { IHotelCardProps } from "./index"
import ImageCarousel from "../../components/ImageCarousel"
import cn from "classnames"
import Rating from "../../components/Rating"
import ErrorPopup from "../ErrorPopup"

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            margin: `${theme.spacing(3)}px 0px`,
        },
        image: {
            maxHeight: 270,
            width: "30%",
            minWidth: "30%",
        },
        textGrid: {
            paddingRight: theme.spacing(6),
            [theme.breakpoints.down("sm")]: {
                paddingRight: theme.spacing(3),
            },
            [theme.breakpoints.down("sm")]: {
                paddingRight: 0,
            },
            maxHeight: 500,
            overflowY: "auto",
        },
        checkbox: {
            color: theme.palette.secondary.main,
            marginTop: theme.spacing(3),
        },
        carouselContainer: {
            margin: theme.spacing(2, 0),
        },
        imgShadowLightgray: {
            boxShadow: `${theme.spacing(24)}px 0px 0px 15px #e3e3e3, ${theme.spacing(6)}px 0px 0px 15px #e3e3e3`,
            [theme.breakpoints.down("sm")]: {
                boxShadow: "none",
            },
        },
        imgShadowDarkgray: {
            boxShadow: `${theme.spacing(24)}px 0px 0px 15px #36404b, ${theme.spacing(6)}px 0px 0px 15px #36404b`,
            [theme.breakpoints.down("sm")]: {
                boxShadow: "none",
            },
        },
        noImgShadow: {
            boxShadow: "none",
            marginTop: -70,
            //objectFit: "cover",
            //paddingBottom: 100,
            marginBottom: 100,
            [theme.breakpoints.down("sm")]: {
                marginTop: 15,
                marginBottom: 0,
            },

        },
        promotedHotelContainer: {
            backgroundColor: theme.palette.secondary.main, //theme.palette.secondary.main,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 45,
            margin: 10,
            marginLeft: 30,
            marginRight: -170,
            color: "white",
            [theme.breakpoints.down("sm")]: {
                marginRight: 0,
                marginLeft: 0,
                paddingLeft: 20,
                paddingRight: 20,
            },
        },
        promotedHotelText: {
            [theme.breakpoints.down("sm")]: {
                textAlign: "center",
            },
        },
        promotedDescription: {
            display: "WebkitBox",
             /* autoprefixer: ignore next */ WebkitBoxOrient: "vertical",
            WebkitLineClamp: 5,
            overflow: "hidden",
            textOverflow: "ellipsis",
        }
    })

type IProps = IHotelCardProps &
    WithStyles<typeof styles> & {
        toggleHotel: IToggleHotel
        selected: boolean
        isSnackbarOpen: boolean
        setSnackbarOpen: (open: boolean) => void
    }
const HotelCardComponent: React.FunctionComponent<IProps> = props => {
    const {
        hotel,
        classes,
        selected,
        toggleHotel,
        fetching,
        isSnackbarOpen,
        setSnackbarOpen,
        numberForAlternatingColor,
        isPromotedHotel,
    } = props
    const [hover, setHover] = useState<boolean>(false)
    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classes.root}>
            <ErrorPopup errors={{ hotels: "Du kan bare velge opp til 5 hoteller på en gang." }} isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setSnackbarOpen} />
            <Grid container direction="column">
                {isPromotedHotel &&
                    <Grid className={classes.promotedHotelContainer}>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h5" className={classes.promotedHotelText}>
                                Hva med å legge deres middag til Rockheim Panorama for en rustikk, velsmakende og annerledes feiring med panoramautsikt over flotte Trondheimsfjorden?
                            </Typography>
                        </Grid>
                    </Grid>
                }
                <Grid container direction="row">
                    <Grid item container xs={2} sm={1} md={1} justify="center" alignItems="flex-start">
                        <Checkbox
                            id="checkbox"
                            className={classes.checkbox}
                            checked={selected}
                            inputProps={{
                                "aria-label": "primary checkbox",
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => toggleHotel(hotel, e.target.checked)}
                        />
                    </Grid>
                    <Grid item xs={10} sm={11} md={5} className={classes.textGrid}>

                        <Typography variant="subtitle1">{hotel.city}</Typography>
                        <Typography variant="h2" gutterBottom>
                            {hotel.name}
                        </Typography>
                        {hotel.tripAdvisorRating !== undefined && hotel.tripAdvisorRating !== null && <Rating rating={hotel.tripAdvisorRating} />}
                        {isPromotedHotel ? <Typography className="promotedDescription" variant="body2"> {hotel.description}</Typography> :
                            <Typography variant="body2"> {hotel.description}</Typography>}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        className={cn(
                            classes.carouselContainer,
                            isPromotedHotel ? classes.noImgShadow : numberForAlternatingColor % 2 === 0 ? classes.imgShadowLightgray : classes.imgShadowDarkgray,
                        )}
                    >
                        {!fetching ? (
                            <ImageCarousel
                                images={hotel.images.sort((image1, image2) => image1.order - image2.order)}
                                hover={hover}
                            />
                        ) : (
                            <div>
                                <Skeleton width={"90%"} />
                                <Skeleton width={"90%"} />
                                <Skeleton width={"80%"} />
                                <Skeleton width={"70%"} />
                                <Skeleton width={"70%"} />
                                <Skeleton width={"40%"} />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(HotelCardComponent)
