import React from "react"
import { connect } from "react-redux"
import { IBookingRequest } from "../../interfaces/IBookingRequest"
import { IChangeBookingRequestAction } from "../../store/actions/booking"
import BookingRequestInfoComponent from "./BookingRequestInfo"

interface IMapStateToProps {
    bookingRequest: IBookingRequest
}

interface IMapDispatchToProps {
    changeBookingRequest: IChangeBookingRequestAction
}

type Iprops = IMapStateToProps & IMapDispatchToProps & {}

interface IState {}

class BookingRequestInfo extends React.Component<Iprops, IState> {
    constructor(props) {
        super(props)
        this.state = {
            values: {
                fullName: "",
                company: "",
                phone: "",
                email: "",
                comment: "",
            },
            errors: {},
        }
    }

    public render() {
        return <BookingRequestInfoComponent />
    }
}

export default connect()(BookingRequestInfo)
