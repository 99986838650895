import Grid from "@material-ui/core/Grid"
import { Theme, withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { createStyles, WithStyles } from "@material-ui/styles"
import React from "react"
import { ERROR_LAYOUT_URL } from "../../routes"

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: 84,
            position: "relative",
            background: theme.palette.primary.main,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxSizing: "border-box",
            padding: `0px ${theme.spacing(20)}px`,
            [theme.breakpoints.down("md")]: {
                padding: `0px ${theme.spacing(12)}px`,
            },
            [theme.breakpoints.down("sm")]: {
                padding: `0px ${theme.spacing(6)}px`,
            },
            [theme.breakpoints.down("xs")]: {
                height: 84,
                padding: `0px ${theme.spacing(3)}px`,
            },
            "& .MuiLink-root": {
                color: "#dbe8f2",
            },
        },
        content: {
            height: "100%",
            color: "#ffffff",
        },
    })

type IProps = WithStyles<typeof styles>

const FooterComponent: React.FunctionComponent<IProps> = props => {
    const { classes } = props

    return (
        <footer className={classes.root}>
            <Grid container direction="row" justify="space-between" alignItems="center" className={classes.content}>
                <Grid xs={5} sm={4} md={3} item container direction="row" justify="space-between">
                    <Grid item>
                        <Link underline="none" href={ERROR_LAYOUT_URL}>
                            <Typography variant="body2">Persornvern</Typography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link underline="none" href={ERROR_LAYOUT_URL}>
                            <Typography noWrap variant="body2">
                                Vilkår for bruk
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
                <Grid xs={5} sm={4} md={3} item container direction="row" justify="space-between">
                    <Grid item>
                        <Typography variant="body2">Norsk</Typography>
                    </Grid>
                    <Grid item>
                        <Typography noWrap variant="body2">
                            &copy; {new Date().getFullYear()} Vincu AS
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </footer>
    )
}

export default withStyles(styles)(FooterComponent)
