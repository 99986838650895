import React from "react"
import BookingComponent from "./Booking"

interface IProps {}

class Booking extends React.Component<IProps, {}> {
    public render() {
        return <BookingComponent />
    }
}

export default Booking
