import { ICity } from "../../interfaces/ICity"
import citiesApi from "../../utils/api/citiesApi"
import { GET_CITIES, GET_CITIES_FULFILLED, GET_CITIES_PENDING, GET_CITIES_REJECTED } from "../types"

export const getCities: IGetCitiesAction = (config?: IGetCitiesConfig) => {
    let cityPromises: Promise<any>[] = []
    for (let countyId = 1; countyId <= 12; countyId++) {
        cityPromises.push(citiesApi.getCities({ countryId: 1, countyId: countyId }))
    }
    return {
        type: GET_CITIES,
        payload: Promise.all(cityPromises),
    }
}

export interface IGetCities {
    type: typeof GET_CITIES
    payload: Promise<any>
}

export interface IGetCitiesPending {
    type: typeof GET_CITIES_PENDING
}

export interface IGetCitiesFulfilled {
    type: typeof GET_CITIES_FULFILLED
    payload: ICity[][] & { messages: any }
}

export interface IGetCitiesRejected {
    type: typeof GET_CITIES_REJECTED
    payload: any | { messages: string }
}

export type IGetCitiesActionReturn = IGetCities | IGetCitiesPending | IGetCitiesFulfilled | IGetCitiesRejected

export type IGetCitiesConfig = {
    countryId: number
    countyId: number
}

export type IGetCitiesAction = (config?: IGetCitiesConfig) => IGetCitiesActionReturn
