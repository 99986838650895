import { Theme } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import { createStyles, withStyles, WithStyles } from "@material-ui/styles"
import Hidden from "@material-ui/core/Hidden"
import cn from "classnames"
import React from "react"
import BookingForm from "../../components/BookingForm"
import AboutVincu from "../../components/AboutVincu"
import backgroundImgMedium from "../../assets/img/vincu/banner_image@2x.png"
import backgroundImgSmall from "../../assets/img/vincu/banner_image.png"
import vincuLogo from "../../assets/img/vincu/vincu_logo_small.png"
import { RouteComponentProps } from "react-router"

const styles = (theme: Theme) => {
    return createStyles({
        root: {
            overflow: "hidden",
        },
        coverImage: {
            height: "80%",
            width: "56.3%",
            top: 0,
            right: 0,
            position: "absolute",
            zIndex: 0,
            backgroundImage: `url(${window.innerWidth > 1200 ? backgroundImgMedium : backgroundImgSmall})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        },
        mainSection: {
            width: "100%",
            position: "relative",
            minHeight: "100vh",
            padding: `${theme.spacing(24)}px ${theme.spacing(4)}px ${theme.spacing(12)}px`,
            [theme.breakpoints.down("lg")]: {
                padding: `${theme.spacing(20)}px ${theme.spacing(4)}px ${theme.spacing(10)}px`,
            },
            [theme.breakpoints.down("md")]: {
                padding: `${theme.spacing(16)}px ${theme.spacing(4)}px ${theme.spacing(8)}px`,
            },
            [theme.breakpoints.down("sm")]: {
                padding: `${theme.spacing(12)}px ${theme.spacing(4)}px ${theme.spacing(6)}px`,
            },
        },
        section: {
            width: "100%",
            position: "relative",
        },
        centerContent: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
        },
        bookingForm: {
            width: "80%",
            zIndex: 2,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
        vincuLogo: {
            position: "absolute",
            width: 100,
            top: "25%",
            left: "15%",
        },
        header: {
            zIndex: 1,
            flexGrow: 1,
            [theme.breakpoints.down("xl")]: {
                width: "40%",
                marginTop: theme.spacing(6),
            },
            [theme.breakpoints.down("lg")]: {
                width: "50%",
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(2),
            },
            [theme.breakpoints.down("md")]: {
                width: "60%",
                margin: theme.spacing(3),
            },
            [theme.breakpoints.down("sm")]: {
                width: "80%",
                margin: theme.spacing(2),
            },
            [theme.breakpoints.down("xs")]: {
                margin: 0,
                width: "100%",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
            },
        },
        divider: {
            backgroundColor: theme.palette.secondary.main,
        },
        //make divider ignore padding on left side
        leftDivider: {
            marginLeft: -theme.spacing(20),
            [theme.breakpoints.down("md")]: {
                marginLeft: -theme.spacing(12),
            },
            [theme.breakpoints.down("sm")]: {
                marginLeft: -theme.spacing(6),
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: -theme.spacing(3),
            },
        },
        //make diveder ignore padding on right side
        rightDivider: {
            marginRight: -theme.spacing(20),
            [theme.breakpoints.down("md")]: {
                marginRight: -theme.spacing(12),
            },
            [theme.breakpoints.down("sm")]: {
                marginRight: -theme.spacing(6),
            },
            [theme.breakpoints.down("xs")]: {
                marginRight: -theme.spacing(3),
            },
        },
        aboutUs: {
            backgroundColor: theme.palette.primary.main,
            padding: `0px ${theme.spacing(20)}px`,
            [theme.breakpoints.down("md")]: {
                padding: `0px ${theme.spacing(12)}px`,
            },
            [theme.breakpoints.down("sm")]: {
                padding: `0px ${theme.spacing(6)}px`,
            },
            [theme.breakpoints.down("xs")]: {
                padding: `0px ${theme.spacing(3)}px`,
            },
        },
        grayBackground: {
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "20%",
            backgroundColor: theme.palette.primary.main,
        },
    })
}

type ILandingComponentProps = RouteComponentProps & {
    styreWeb: boolean
}

const LandingComponent: React.FunctionComponent<ILandingComponentProps & WithStyles<typeof styles>> = props => {
    const { classes, styreWeb } = props
    return (
        <div className={classes.root}>
            <div className={cn(classes.mainSection, classes.centerContent)}>
                <div className={classes.coverImage} />
                <Hidden mdDown>
                    {styreWeb && <img src={vincuLogo} alt="vincuLogo" className={classes.vincuLogo} />}
                </Hidden>
                <div className={classes.header}>
                    <Typography gutterBottom variant="h1">
                        {styreWeb
                            ? "Her finner du gode alternativer til deres arrangement"
                            : "Book ditt neste arrangement nå"}
                    </Typography>
                    <Hidden xsDown>
                        <Typography variant="body1">
                                Vincu gjør det enkelt for deg å finne lokaler til ditt neste arrangement
                        </Typography>
                    </Hidden>
                </div>
                <div className={classes.grayBackground}></div>
                <div className={classes.bookingForm}>
                    <BookingForm />
                </div>
            </div>
            <div className={classes.aboutUs}>
                <Divider className={cn(classes.divider, classes.leftDivider)} />
                <div className={cn(classes.section)}>
                    <AboutVincu />
                </div>
                <Divider className={cn(classes.divider, classes.rightDivider)} />
            </div>
        </div>
    )
}

export default withStyles(styles)(LandingComponent)
