import React, { useState } from "react"
import { Theme, withStyles } from "@material-ui/core/styles"
import { createStyles, WithStyles } from "@material-ui/styles"
import mailIcon from "../../assets/img/vincu/mail_1@25.png"
import phoneIcon from "../../assets/img/vincu/tel_1@2x@2x.png"
import { Typography } from "@material-ui/core"

const styles = (theme: Theme) =>
    createStyles({
        root: {
            "& ul": {
                listStyle: "none",
                margin: 0,
                padding: 0,
            },
            "& .MuiButton-root": {
                borderRadius: "25px",
                backgroundColor: "#ffffff",
            },
        },
        icon: {
            height: 20,
            width: 20,
            paddingRight: 4,
        },
        contactIcon: {
            height: 30,
            width: 30,
        },
        dropdown: {
            position: "absolute",
            top: 58,
            width: 150,
            transform: "translateX(-40%)",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0px 0px 10px 0px rgba(150,150,150,0.75)",
            zIndex: 2,
        },
        menuItem: {
            height: 50,
            display: "flex",
            alignItems: "center",
            transition: "background 500ms",
            padding: "0.5rem",
        },
        contactDropdown: {
            cursor: "pointer",
            "&:hover": {
                borderBottom: "2px solid black",
            }
        },
        navbarText: {
            fontSize: 20,
            fontWeight: 500,
            [theme.breakpoints.down("sm")]: {
                fontSize: 20,
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: 18,
            },
            fontFamily: "Poppins",
        }

    })

type IProps = {}

const ContactDropdownComponent: React.FunctionComponent<IProps & WithStyles<typeof styles>> = props => {
    const { classes } = props
    const [open, setOpen] = useState<boolean>(false)
    return (
        <div className={classes.root}>
            <div className={classes.contactDropdown} onClick={() => setOpen(!open)}>
                <Typography className={classes.navbarText}>Kontakt oss</Typography>
            </div>
            {open && (
                <ul className={classes.dropdown}>
                    <li className={classes.menuItem}>
                        <img src={phoneIcon} alt={"phoneIcon"} className={classes.icon} />
                        <Typography variant="body2">480 93 178</Typography>
                    </li>
                    <li className={classes.menuItem}>
                        <img src={mailIcon} alt={"mailIcon"} className={classes.icon} />
                        <Typography variant="body2">info@vincu.no</Typography>
                    </li>
                </ul>
            )}
        </div>
    )
}

export default withStyles(styles)(ContactDropdownComponent)
