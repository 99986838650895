import { IEventType } from "../../interfaces/IEventType"
import eventTypeApi from "../../utils/api/eventTypesApi"
import { GET_EVENT_TYPES, GET_EVENT_TYPES_FULFILLED, GET_EVENT_TYPES_PENDING, GET_EVENT_TYPES_REJECTED } from "../types"

export const getEventTypes: IGetEventTypesAction = () => {
    return {
        type: GET_EVENT_TYPES,
        payload: eventTypeApi.getEventTypes(),
    }
}

export interface IGetEventTypes {
    type: typeof GET_EVENT_TYPES
    payload: Promise<any>
}

export interface IGetEventTypesPending {
    type: typeof GET_EVENT_TYPES_PENDING
}

export interface IGetEventTypesFulfilled {
    type: typeof GET_EVENT_TYPES_FULFILLED
    payload: IEventType[] & { messages: any }
}

export interface IGetEventTypesRejected {
    type: typeof GET_EVENT_TYPES_REJECTED
    payload: any | { messages: string }
}

export type IGetEventTypesActionReturn =
    | IGetEventTypes
    | IGetEventTypesPending
    | IGetEventTypesFulfilled
    | IGetEventTypesRejected

export type IGetEventTypesAction = () => IGetEventTypesActionReturn
