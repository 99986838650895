import { StepperProps } from "@material-ui/core/Stepper"
import React from "react"
import StepperComponent from "./Stepper"

export interface IProps extends Omit<StepperProps, "children"> {
    steps: Array<{ label: string; href: string }>
}

class Stepper extends React.Component<IProps> {
    public render() {
        return <StepperComponent {...this.props} />
    }
}

export default Stepper
