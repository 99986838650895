import { createStyles, Stepper, Theme } from "@material-ui/core"
import Link from "@material-ui/core/Link"
import Step from "@material-ui/core/Step"
import StepConnector from "@material-ui/core/StepConnector"
import StepLabel from "@material-ui/core/StepLabel"
import { withStyles } from "@material-ui/core/styles"
import { WithStyles } from "@material-ui/styles"
import React from "react"
import { IProps as ParentProps } from "./"
import StepIcon from "./StepIcon"
import cn from "classnames"

const styles = (theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down("md")]: {
                padding: 0,
            },
        },
        link: {
            fontSize: 16,
        },
        disabledLink: {
            pointerEvents: "none",
        },
})

const Connector = withStyles((theme: Theme) => ({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    active: {
        "& $line": {
            borderColor: theme.palette.primary.light,
        },
    },
    completed: {
        "& $line": {
            borderColor: theme.palette.primary.dark,
        },
    },
    line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
    },
}))(StepConnector)

type IProps = WithStyles<typeof styles> & ParentProps

const StepperComponent: React.FunctionComponent<IProps> = props => {
    const { classes, activeStep, steps, ...rest } = props
    return (
        <Stepper className={classes.root} activeStep={activeStep} alternativeLabel connector={<Connector />} {...rest}>
            {steps.map((step, i: number) => (
                <Step key={step.label}>
                    <StepLabel StepIconComponent={StepIcon}>
                        <Link
                            className={cn(classes.link, activeStep !== undefined && activeStep <= i ? classes.disabledLink : "")}
                            color={"primary"}
                            underline={i === activeStep ? "none" : "hover"}
                            href={step.href}
                        >
                            {step.label}
                        </Link>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

export default withStyles(styles)(StepperComponent)
