import axios from "axios"
import { IGetCitiesConfig } from "../../store/actions/cities"

const getCities = (config: IGetCitiesConfig) => {
    return axios.get(
        `https://vincu.azurewebsites.net/api/v1/countries/${config.countryId}/counties/${config.countyId}/cities`,
    )
}

export default { getCities }
