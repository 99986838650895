import { IReduxState, store } from "../"
import { IHotel } from "../../interfaces/IHotel"
import { IFilters } from "../../interfaces/IPickHotelsView"
import hotelsApi from "../../utils/api/hotelsApi"
import { pick } from "../../utils/object"
import { FIND_ALL_HOTELS, FIND_ALL_HOTELS_FULFILLED, FIND_ALL_HOTELS_PENDING, FIND_ALL_HOTELS_REJECTED } from "../types"

const filterParams = ["numberOfGuests", "numberOfBeds", "numberOfDinners", "from", "to", "eventTypes", "city"]

export const getHotels: IGetHotelsAction = (config = null) => {
    const state: IReduxState = store.getState()
    const params = config ? config : pick({ ...state.bookingRequest.data }, filterParams)
    //restructure object to fit with api
    const { city, ...restParams } = params
    const restructuredParams = {
        cityId: city?.id,
        ...restParams,
    }
    return {
        type: FIND_ALL_HOTELS,
        payload: hotelsApi.getHotels({ ...restructuredParams }),
    }
}

export interface IGetHotels {
    type: typeof FIND_ALL_HOTELS
    payload: Promise<any>
}

export interface IGetHotelsPending {
    type: typeof FIND_ALL_HOTELS_PENDING
}

export interface IGetHotelsFulfilled {
    type: typeof FIND_ALL_HOTELS_FULFILLED
    payload: { data: IHotel[] } & { messages: any }
}



export interface IGetHotelsRejected {
    type: typeof FIND_ALL_HOTELS_REJECTED
    payload: any | { messages: string }
}

export type IGetHotelsActionReturn = IGetHotels | IGetHotelsFulfilled | IGetHotelsRejected | IGetHotelsPending
export type IGetHotelsConfig = IFilters | null
export type IGetHotelsAction = (config?: IGetHotelsConfig) => IGetHotelsActionReturn

