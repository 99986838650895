import { MenuItem, Theme, Button } from "@material-ui/core"
import Grid, { GridProps } from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import HelpIcon from "@material-ui/icons/Help"
import { withStyles, WithStyles } from "@material-ui/styles"
import React from "react"
import { IBookingRequest, ITouched } from "../../interfaces/IBookingRequest"
import { IErrors } from "../../validators/bookingRequest.validator"
import PhoneField from "../Fields/PhoneField"
import SelectField from "../Fields/SelectField"
import TextField from "../Fields/TextField"
import TextArea from "../Fields/TextArea"
import { IOnChange } from "../Fields/types"
import { IFormValues } from "./index"
import ErrorPopup from "../ErrorPopup"

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(6),
        "& .MuiTextField-root": {
            width: "25ch",
        },
        "& .MuiInputAdornment-root": {
            display: "contents",
        },
    },
    submitButtonContainer: {
        display: "flex",
        justifyContent: "center",
    },
    submitButton: {
        padding: theme.spacing(2, 4),
    },
})

type IProps = WithStyles<typeof styles> & {
    errors: IErrors<IBookingRequest>
    values: IFormValues
    onChange: IOnChange
    onSubmit: () => void
    touched: ITouched<IFormValues>
    onTouched: (fieldName: string) => () => void
    isSnackbarOpen: boolean
    setIsSnackbarOpen: (isSnackbarOpen: boolean) => void
}

const GridItem: React.FunctionComponent<GridProps> = props => {
    return <Grid xs={12} item {...props} />
}

const BookingInformationFormComponent: React.FunctionComponent<IProps> = props => {
    const { classes, errors, touched, onTouched, values, onChange, onSubmit, isSnackbarOpen, setIsSnackbarOpen } = props
    return (
        <form className={classes.root}>
            <ErrorPopup  errors={errors} isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} />
            <Grid container justify="space-between" spacing={10}>
                <Grid container item sm={6} md={4} alignContent="space-between">
                    <GridItem>
                        <TextField
                            onTouched={onTouched("fullName")}
                            name={"fullName"}
                            value={values.fullName}
                            error={touched.fullName && errors.fullName ? errors.fullName : undefined}
                            label={"Navn"}
                            required={true}
                            onChange={onChange}
                            fullWidth
                        />
                    </GridItem>
                    <GridItem>
                        <TextField
                            onTouched={onTouched("email")}
                            name={"email"}
                            value={values.email}
                            error={touched.email && errors.email ? errors.email : undefined}
                            label={"E-post"}
                            required={true}
                            onChange={onChange}
                            fullWidth
                        />
                    </GridItem>
                    <GridItem>
                        <SelectField
                            onTouched={onTouched("reference")}
                            name={"reference"}
                            value={values.reference}
                            error={touched.reference && errors.reference ? errors.reference : undefined}
                            label={"Hvor hørte du om oss?"}
                            onChange={onChange}
                            fullWidth
                        >
                            {["SMB-tjenester", "StyreWeb"].map((referenceOption, i) => {
                                return (
                                    <MenuItem key={referenceOption} value={referenceOption}>
                                        {referenceOption}
                                    </MenuItem>
                                )
                            })}
                        </SelectField>
                    </GridItem>
                </Grid>
                <Grid container item sm={6} md={4} alignContent="space-between">
                    <GridItem>
                        <TextField
                            name={"company"}
                            value={values.company}
                            error={errors.company}
                            label={"Firma"}
                            onChange={onChange}
                            required={false}
                            fullWidth
                        />
                    </GridItem>
                    <GridItem>
                        <PhoneField
                            onTouched={onTouched("phone")}
                            name={"phone"}
                            value={values.phone}
                            error={touched.phone && errors.phone ? errors.phone : undefined}
                            label={"Telefonnummer"}
                            required={true}
                            onChange={onChange}
                            defaultCountry="no"
                        />
                    </GridItem>
                    {/* Lazy solution, should fix */}
                    <GridItem style={{ visibility: "hidden" }}>
                        <p>test</p>
                    </GridItem>
                </Grid>
                <Grid container item sm={12} md={4}>
                    <GridItem>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            style={{ padding: "10px 0px" }}
                        >
                            <Typography variant="body2">Kommentarer / spesielle behov</Typography>
                            <InputAdornment position="end">
                                <Tooltip
                                    enterTouchDelay={0}
                                    leaveTouchDelay={500}
                                    placement={"top"}
                                    title={
                                        <div>
                                            <h2>Tips til ting man bør tenke over:</h2>
                                            <h3>- Allergier</h3>
                                            <h3>- Teleslynge</h3>
                                            <h3>- Spesielt teknisk utstyr</h3>
                                            <h3>- Handicap, hvor mange og hva trengs fra arrangør?</h3>
                                        </div>
                                    }
                                >
                                    <HelpIcon color={"primary"} />
                                </Tooltip>
                            </InputAdornment>
                        </Grid>
                        <TextArea
                            name={"comment"}
                            value={values.comment}
                            error={errors.comment}
                            required={false}
                            onChange={onChange}
                            fullWidth
                            multiline
                            placeholder={"Skriv inn noe her..."}
                            rows={20}
                            variant="filled"
                        />
                    </GridItem>
                </Grid>
                <GridItem className={classes.submitButtonContainer} xs={12} sm={12}>
                    <Button
                        color="secondary"
                        size={"large"}
                        variant="contained"
                        onClick={onSubmit}
                        className={classes.submitButton}
                    >
                        Neste
                    </Button>
                </GridItem>
            </Grid>
        </form>
    )
}

export default withStyles(styles)(BookingInformationFormComponent)
