import axios from "axios"
import { IGetHotelsConfig } from "../../store/actions/hotels"
const URL = "/hotels/search"

const getHotels = (config: IGetHotelsConfig) => {
    return axios.get("https://vincu.azurewebsites.net/api/v1" + URL + "/", { params: config })
}

const getHotel = (hotelId: number) => {
    return axios.get("https://vincu.azurewebsites.net/api/v1/hotels/" + hotelId)
}


export default { getHotels, getHotel }
