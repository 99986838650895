import { TextField, Theme, Button } from "@material-ui/core"
import CardContent from "@material-ui/core/CardContent"
import CircularProgress from "@material-ui/core/CircularProgress"
import { withStyles } from "@material-ui/core/styles"
import { WithStyles } from "@material-ui/styles"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { useState } from "react"
import { ITouched } from "../../interfaces/IBookingRequest"
import { ICity } from "../../interfaces/ICity"
import { ICitiesState, IBookingRequestState } from "../../store"
import { IErrors } from "../../validators/bookingRequest.validator"
import DatePickerField from "../Fields/DatePicker"
import SliderField from "../Fields/Slider"
import { IOnChange } from "../Fields/types"
import { IFormValues } from "./index"
import ErrorPopup from "../ErrorPopup"

const styles = (theme: Theme) => ({
    root: {
        "& .MuiCardContent-root": {
            padding: 0,
        },
        "& .MuiAutocomplete-root": {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
    },
    form: {
        "& > *": {
            marginBottom: theme.spacing(2),
        },
    },
    extraMeetingCheckbox: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginLeft: 0,
    },
    checkbox: {
        color: theme.palette.primary.light,
    },
    hidden: {
        display: "none",
    },
})

type IProps = WithStyles<typeof styles> & {
    values: IFormValues
    onChange: IOnChange<any>
    errors: IErrors<IFormValues>
    touched: ITouched<IFormValues>
    isValid: () => boolean
    onTouched: (fieldName: string) => () => void
    onSubmit: () => void
    citiesState: ICitiesState
    bookingRequestState: IBookingRequestState
    isSnackbarOpen: boolean
    setIsSnackbarOpen: (isSnackbarOpen: boolean) => void
}

const FiltersComponent: React.FunctionComponent<IProps> = props => {
    const {
        classes,
        values,
        onChange,
        errors,
        onSubmit,
        citiesState: { data, fetching },
        bookingRequestState: { data: { styreWeb } },
        isSnackbarOpen,
        setIsSnackbarOpen,
    } = props
    const [showExtraMeetingRoom, setShowExtraMeetingRoom] = useState<boolean>(false)
    const options = data?.sort((a: ICity, b: ICity) => a.name.localeCompare(b.name, "nb"))
    return (
        <div className={classes.root}>
            <ErrorPopup  errors={errors} isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} />
            <form className={classes.form}>
                <div>
                    <CardContent>
                        <Autocomplete
                            onChange={(event: any, value: ICity | null) => {
                                if (value) {
                                    onChange("city", value)
                                }
                            }}
                            options={options ? options : []}
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) => option.name === value.name}
                            value={values.city}
                            autoHighlight
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    required={true}
                                    label={"By/område"}
                                    fullWidth
                                    error={!!errors.city}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {fetching ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <DatePickerField
                            value={values.from}
                            onChange={onChange}
                            name={"from"}
                            label={"Dato fra"}
                            required
                            error={errors.from}
                        />
                        <DatePickerField
                            onChange={onChange}
                            value={values.to}
                            name={"to"}
                            label={"Dato til"}
                            required
                            error={errors.to}
                        />
                        <SliderField
                            onChange={onChange}
                            value={values.numberOfGuests}
                            error={errors.numberOfGuests}
                            name={"numberOfGuests"}
                            label={styreWeb ? "Antall deltakere (ca.)" : "Antall gjester"}
                            required
                            step={5}
                            min={1}
                            max={1000}
                            withTextField
                        />
                        <SliderField
                            onChange={onChange}
                            value={values.numberOfBeds}
                            error={errors.numberOfBeds}
                            name={"numberOfBeds"}
                            label={"Overnattinger per døgn"}
                            step={5}
                            min={0}
                            max={1000}
                            withTextField
                        />

                        <SliderField
                            onChange={onChange}
                            value={values.numberOfDinners}
                            error={errors.numberOfDinners}
                            onChangeCommitted={onSubmit}
                            name={"numberOfDinners"}
                            label={"Antall middager per dag"}
                            step={5}
                            min={0}
                            max={1000}
                            withTextField
                        />
                        <FormControlLabel
                            className={classes.extraMeetingCheckbox}
                            control={
                                <Checkbox
                                    className={classes.checkbox}
                                    checked={showExtraMeetingRoom}
                                    onChange={() => setShowExtraMeetingRoom(!showExtraMeetingRoom)}
                                />
                            }
                            label="Ekstra møterom?"
                            labelPlacement="start"
                        />
                        <div className={!showExtraMeetingRoom ? classes.hidden : ""}>
                            <SliderField
                                onChange={onChange}
                                value={values.numberOfMeetingRooms}
                                error={errors.numberOfMeetingRooms}
                                onChangeCommitted={onSubmit}
                                name={"numberOfMeetingRooms"}
                                label={"Hvor mange?"}
                                step={1}
                                min={0}
                                max={100}
                                withTextField
                            />
                        </div>
                        <div>
                            <Button
                                id="nextButton"
                                color="secondary"
                                variant="contained"
                                size="large"
                                onClick={onSubmit}
                            >
                                Neste
                            </Button>
                        </div>
                    </CardContent>
                </div>
            </form>
        </div>
    )
}

export default withStyles(styles)(FiltersComponent)
