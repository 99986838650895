import { Theme, Typography, Grid, Button } from "@material-ui/core"
import { createStyles, withStyles, WithStyles } from "@material-ui/styles"
import cn from "classnames"
import { globalStyles } from "../../styles/themes/vincu"
import React from "react"
import { Link } from "react-router-dom"
import CancelRoundedIcon from "@material-ui/icons/CancelRounded"
import { LANDING_LAYOUT_URL } from "../../routes"
import mailIcon from "../../assets/img/vincu/mail_1@25.png"
import phoneIcon from "../../assets/img/vincu/tel_1@2x@2x.png"

export interface IProps {}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(20),
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
                marginBottom: theme.spacing(4),
                [theme.breakpoints.down("md")]: {
                    marginBottom: theme.spacing(2),
                },
            },
        },
        centerContent: globalStyles.centerContent,
        errorCross: {
            marginLeft: theme.spacing(2),
            width: 40,
            height: 40,
            color: theme.palette.error.light,
        },
        contactInfoBox: {
            padding: theme.spacing(4),
            [theme.breakpoints.down("md")]: {
                padding: theme.spacing(2),
            },
            backgroundColor: theme.palette.info.main,
        },
        submitButton: {
            borderRadius: 0,
            padding: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(1),
            },
            backgroundColor: theme.palette.primary.light,
            color: "#ffffff",
        },
        icon: {
            height: 25,
            width: 25,
            marginRight: theme.spacing(2),
        },
        infoWrapper: {
            padding: theme.spacing(1),
        },
        errorCrossContainer: {
            justifyContent: "center",
        },
        textContainer: {
            maxWidth: "45%",
            [theme.breakpoints.down("md")]: {
                maxWidth: "60%",
            },
            [theme.breakpoints.down("md")]: {
                maxWidth: "70%",
            },
        },  
    })

interface IErrorComponentProps {}

const Error: React.FunctionComponent<IErrorComponentProps & WithStyles<typeof styles>> = props => {
    const { classes } = props
    return (
        <div className={cn(classes.root, classes.centerContent)}>
            <Typography variant="h1">Beklager!</Typography>
            <Grid container className={classes.errorCrossContainer}>
                <Typography variant="body1" align="center" gutterBottom>
                    Noe gikk galt her ...
                </Typography>
                <CancelRoundedIcon className={classes.errorCross} />
            </Grid>
            <Grid className={classes.textContainer}>
                <Typography variant={"body1"} align="center">
                    Det ser ut som at serveren vår ikke fungerer som den skal. Takk for tålmodigheten!
                </Typography>
            </Grid>
            <Grid className={classes.contactInfoBox}>
                <Typography variant={"body1"} align="center" className={classes.infoWrapper}>
                    Kontakt oss:
                </Typography>
                <Grid container className={classes.infoWrapper}>
                    <img src={phoneIcon} alt={"phoneIcon"} className={classes.icon} />
                    <Typography noWrap variant="body2">
                        480 93 178
                    </Typography>
                </Grid>
                <Grid container className={classes.infoWrapper}>
                    <img src={mailIcon} alt={"mailIcon"} className={classes.icon} />
                    <Typography variant="body2">info@vincu.no</Typography>
                </Grid>
            </Grid>
            <Typography variant={"body1"} align="center">
                eller
            </Typography>
            <Button
                component={Link}
                to={LANDING_LAYOUT_URL}
                variant={"contained"}
                size={"large"}
                className={classes.submitButton}
            >
                Tilbake til forsiden
            </Button>
        </div>
    )
}

export default withStyles(styles)(Error)
