import { applyMiddleware, createStore } from "redux"
import logger from "redux-logger"
import { persistStore } from "redux-persist"
import promise from "redux-promise-middleware"
import thunk from "redux-thunk"
import { IBookingRequest, ITouched } from "../interfaces/IBookingRequest"
import { IHotel } from "../interfaces/IHotel"
import { ICity } from "../interfaces/ICity"
import { IEventType } from "../interfaces/IEventType"
import { IActionsReturn } from "./actions"
import reducer, { ReduxState } from "./reducers"

export interface IBookingRequestState {
    data: IBookingRequest
    errors: { [key in keyof IBookingRequest]: string }
    touched: { [key in keyof ITouched<IBookingRequest>]: boolean }
}

export interface IHotelsState {
    data?: {
        hotels: IHotel[]
    }
    fetching: boolean
    messages: any[]
}

export interface IPromotedHotelsState {
    data?: IHotel
    fetching: boolean
    messages: any[]
}

export interface ICitiesState {
    data?: ICity[]
    fetching: boolean
    messages: any[]
}
export interface IEventTypesState {
    data?: IEventType[]
    fetching: boolean
    messages: any[]
}

export interface IReduxState {
    hotels: IHotelsState
    promotedHotels: IPromotedHotelsState
    cities: ICitiesState
    eventTypes: IEventTypesState
    customization: {
        styleType: string
    }
    bookingRequest: IBookingRequestState
}

export const initialState: IReduxState = {
    hotels: {
        data: undefined,
        fetching: false,
        messages: [],
    },
    promotedHotels: {
        data: undefined,
        fetching: false,
        messages: [],
    },
    cities: {
        data: undefined,
        fetching: false,
        messages: [],
    },
    eventTypes: {
        data: undefined,
        fetching: false,
        messages: [],
    },
    customization: {
        styleType: "light",
    },
    bookingRequest: {
        data: {
            fullName: undefined,
            phone: undefined,
            email: undefined,
            comment: undefined,
            eventTypes: [],
            hotels: [],
            numberOfGuests: 1,
            numberOfBeds: 0,
            numberOfDinners: 0,
            numberOfMeetingRooms: 0,
            from: new Date(),
            to: new Date(),
            styreWeb: false,
        },
        errors: {},
        touched: {},
    },
}

export const store = createStore<IReduxState, IActionsReturn, {}, {}>(
    reducer,
    initialState as ReduxState,
    applyMiddleware(logger, thunk, promise),
)
export const persistor = persistStore(store)
