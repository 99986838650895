import validator from "validator"
import { IBookingRequest } from "../interfaces/IBookingRequest"
type MobilePhoneLocale = validator.MobilePhoneLocale

export type IErrors<T = any> = { [key in keyof T]?: string }

export const PERMITTED_LOCALES: (MobilePhoneLocale | "sv-SE")[] = ["nb-NO", "da-DK", "fi-FI", "sv-SE"]

export const validateBookingRequest = (bookingRequest: IBookingRequest): IErrors => {
    const errors: IErrors<IBookingRequest> = {}
    const { numberOfGuests, hotels, email, fullName, phone, from, to, city, eventTypes } = bookingRequest
    if (!numberOfGuests || numberOfGuests <= 0) {
        errors.numberOfGuests = "Du må oppgi et gyldig antall gjester"
    }
    if (!hotels || hotels.length <= 0) {
        errors.hotels = "Du må velge minst ett hotell"
    }
    if (hotels && hotels.length > 5) {
        errors.hotels = "Du kan velge maks 5 hoteller"
    }
    if (!city) {
        errors.city = "Du må oppgi en by"
    }
    if (!email) {
        errors.email = "Du må oppgi en e-post-adresse"
    } else if (email && !validator.isEmail(email)) {
        errors.email = "Du må oppgi en gyldig e-post-adresse"
    }
    if (!fullName) {
        errors.fullName = "Du må oppgi et gyldig navn"
    }
    if (!phone) {
        errors.phone = "Du må oppgi et telefonnummer"
    } else if (
        phone &&
        !validator.isMobilePhone(phone.replace(" ", ""), PERMITTED_LOCALES as MobilePhoneLocale[], {
            strictMode: true,
        })
    ) {
        errors.phone = "Du må oppgi et gyldig telefonnummer"
    }
    if (!from) {
        errors.from = "Du må oppgi en gyldig fra-dato"
    } else if (!to) {
        errors.to = "Du må oppgi en gyldig til-dato"
    } else if (from > to) {
        errors.to = "Til-dato må være lik eller etter fra-dato"
        errors.from = "Fra-dato må være like eller før til-dato"
    }
    if (!eventTypes || eventTypes.length <= 0) {
        errors.eventTypes = "Du må oppgi en gyldig arrangementtype"
    }

    return errors
}
