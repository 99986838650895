import React from "react"
import { Theme, withStyles } from "@material-ui/core/styles"
import { createStyles, WithStyles } from "@material-ui/styles"
import { Button, Typography } from "@material-ui/core"
import cn from "classnames"
import { globalStyles } from "../../styles/themes/vincu"


const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
        },
        textContainer: {
            marginBottom: theme.spacing(3),
        },
        buttonContainer: {
            width: "100%",
        },
        closeButton: {
            borderRadius: 0,
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.light,
            color: "#ffffff",
            [theme.breakpoints.down("xs")]: { padding: theme.spacing(1) },
        },
        centerContent: globalStyles.centerContent,
    })

type IProps = {
    text: string,
    onClose: () => void,
}


const TutorialCardComponent: React.FunctionComponent<IProps & WithStyles<typeof styles>> = props => {
    const { classes, text, onClose } = props

    return (
        <div className={classes.root}>
            <div className={classes.textContainer}>
                <Typography variant="body2">{text}</Typography>
            </div>
            <div className={cn(classes.buttonContainer, classes.centerContent)}>
                <Button
                    size="small"
                    className={classes.closeButton}
                    onClick={onClose}
                    variant="contained"
                    disableRipple
                >
                    <Typography variant="button">Skjønner!</Typography>
                </Button>
            </div>
        </div>
    )
}

export default withStyles(styles)(TutorialCardComponent)