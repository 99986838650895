import { Theme, Grid, Button, GridProps, Typography } from "@material-ui/core"
import { createStyles, withStyles, WithStyles } from "@material-ui/styles"
import cn from "classnames"
import { globalStyles } from "../../styles/themes/vincu"
import React from "react"
import { Link } from "react-router-dom"
import { LANDING_LAYOUT_URL } from "../../routes"
import map from "../../assets/img/vincu/map1@3x.jpg"
import { faSquareFull as squareIcon } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Ole from "../../assets/img/vincu/Ole.jpg"
import Anders from "../../assets/img/vincu/Anders.jpg"
import Vegard from "../../assets/img/vincu/veg.png"
import Kristian from "../../assets/img/vincu/kristian.jpg"

export interface IProps {}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(20),
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
                marginBottom: theme.spacing(4),
                [theme.breakpoints.down("md")]: {
                    marginBottom: theme.spacing(2),
                },
            },
        },
        centerContent: globalStyles.centerContent,
        errorCross: {
            marginLeft: theme.spacing(2),
            width: 40,
            height: 40,
            color: theme.palette.error.light,
        },
        contactInfoBox: {
            padding: theme.spacing(4),
            [theme.breakpoints.down("md")]: {
                padding: theme.spacing(2),
            },
            backgroundColor: theme.palette.info.main,
        },
        submitButton: {
            borderRadius: 0,
            padding: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(1),
            },
            backgroundColor: theme.palette.primary.light,
            color: "#ffffff",
        },
        icon: {
            height: 25,
            width: 25,
            marginRight: theme.spacing(2),
        },
        infoWrapper: {
            padding: theme.spacing(1),
        },
        errorCrossContainer: {
            justifyContent: "center",
        },
        mapImage: {
            maxHeight: 300,
            maxWidth: "100%",
            [theme.breakpoints.down("xs")]: {
                maxWidth: 270,
                height: 200,
            },
        },
        aboutUsContainer: {
            display: "flex",
            flexDirection: "row",
            maxWidth: "100%",
        },
        teamNameContainer: {
            display: "flex",
            flexDirection: "row",
            flexItems: "margin-left",
            alignItems: "center",
            textAlign: "left",
            marginTop: 60,
            marginLeft: -15,
            marginRight: -15,
        },
        aboutUsContainerColumn: {
            display: "flex",
            flexDirection: "column",
            margin: 35,
            [theme.breakpoints.down("md")]: {
                maxWidth: 300, 
                padding: theme.spacing(4),
            },
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(4),
            },
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(2),
                maxWidth: 270, 
            },
        },
        aboutUsText: {
            width: 400,
            display: "flex",
            alignItems: "center",
        },
        imgShadowLightgray: {
            height: 310,
            objectFit: "cover",
            boxShadow: `${theme.spacing(0)}0px 0px 0px 0px #e3e3e3, ${theme.spacing(0)}0px 30px 0px 20px #e3e3e3`,
            
        },
        imgShadowDarkgray: {
            height: 310,
            objectFit: "cover",
            boxShadow: `${theme.spacing(14)}0px 0px 0px 0px #36404b, ${theme.spacing(0)}px 30px 0px 20px #36404b`,
            marginTop: 40,
        },
    })

interface IErrorComponentProps {}

const diamondIcon =  <FontAwesomeIcon icon={squareIcon} transform={{rotate: 45}}></FontAwesomeIcon>

const GridItem: React.FunctionComponent<GridProps> = props => {
    return <Grid xs={12} sm={6} md={6} lg={2} item {...props} />
}

const employees = [{name: "Anders Rommetvedt Malmo", 
description: "Anders er ansvarlig for det meste som har med IT å gjøre i Vincu. Spesifikt API, backend og administratorfunksjoner til www.vincu.no Han vil dermed også naturlig nok jobbe mest med videreutvikling av portalen i den kommende tiden.",
image: Anders}, {name: "Ole Christian Hoven", description: "Ole er en av grunderne bak Vincu og har vært med siden oppstarten i 2019. Han har flere års erfaring som Salgssjef i Nordic Choice Hotels, primært med fokus innenfor kurs og konferanse-segmentet. Ole er utdannet innen økonomi og administrasjon.", image: Ole},
{name: "Vegar Langenes", description: "Er utdannet innen Økonomi og Administrasjon ved høyskolen BI i Trondheim, med prosjektledelse som fordypning. Har jobbet med Vincu siden oppstarten i 2019. Vegar kommer til å jobbe primært med økonomi og administrative oppgaver i Vincu fremover.", image: Vegard},
{name: "Kristian Hoseth Mikkelsen", description: "Kristian er en av grunderne og jobber som daglig leder i Vincu. Han har tung bakgrunn fra hotell og reiselivsnæringen samt utdanning fra BI. Kristians viktigste oppgave vil være å strategisk drifte selskapet fremover, samt dag-til-dag oppgaver.", image: Kristian}]


const AboutUs: React.FunctionComponent<IErrorComponentProps & WithStyles<typeof styles>> = props => {
    const { classes } = props
    return (
        <div className={cn(classes.root, classes.centerContent)}>
            <Typography variant="h2">
                Om oss
            </Typography>
            <Grid container direction="row" justify="center" className={classes.aboutUsContainer}>
                {employees.map((employee, index) => (
                    <GridItem key={index} className={classes.aboutUsContainerColumn}> 
                    <img src={employee.image} className={index%2===0 ? classes.imgShadowLightgray:classes.imgShadowDarkgray} alt="personImage" />
                    <div className={classes.teamNameContainer}> <span style={{marginRight: 10}}>{diamondIcon}</span> <Typography variant="h5"> {employee.name} </Typography>  
                    </div> <Typography style={{marginLeft: -15, marginRight: -15}}variant="h6">{employee.description} </Typography></GridItem>
                ))}
            </Grid>
            <Grid container spacing={3} direction="row" justify="center" className={classes.aboutUsContainer}>
                <Grid xs={12} sm={5} md={4} item className={classes.aboutUsText} >  
                    <Typography variant="h6">
                        Vi i Vincu ønsker å forenkle ditt neste arrangement. Når du eller din bedrift/lag/forening planlegger fremtidige arrangementer, innhenter vi pristilbud og tilgjengelighet for deg, helt gratis og uforpliktende.
                        Har du spørsmål er du velkommen til å kontakte oss på kontaktinformasjonen øverst på våre sider. Vi ser frem til å høre fra deg! 
                    </Typography>
                </Grid> 
                <Grid xs={8} sm={5} md={4} item container alignItems="center" justify="center">
                    <img src={map} className={classes.mapImage} alt="Map" />
                </Grid>
            </Grid>
            <Button
                component={Link}
                to={LANDING_LAYOUT_URL}
                variant={"contained"}
                size={"large"}
                className={classes.submitButton}
            >
                Tilbake til forsiden
            </Button>
        </div>
    )
}

export default withStyles(styles)(AboutUs)
