import isEmpty from "lodash/isEmpty"
import React, { useState } from "react"
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { compose } from "redux"
import { IBookingRequest, ITouched } from "../../interfaces/IBookingRequest"
import { SUMMARY_VIEW_URL } from "../../routes"
import { IReduxState } from "../../store"
import {
    changeBookingRequest,
    IChangeBookingRequestAction,
    ISetTouchedAction,
    setTouched,
} from "../../store/actions/booking"
import { pick } from "../../utils/object"
import { IErrors } from "../../validators/bookingRequest.validator"
import { IOnChange } from "../Fields/types"
import BookingInformationFormComponent from "./BookingInformationForm"

export const formFields: Array<keyof IFormValues> = ["hotels", "fullName", "company", "phone", "email", "comment", "reference"]
export type IFormValues = Pick<IBookingRequest, "hotels" | "fullName" | "company" | "phone" | "email" | "comment" | "reference">

interface IMapStateToProps {
    errors: IErrors<IFormValues>
    touched: ITouched<IFormValues>
    values: IBookingRequest
    bookingRequestErrors: IErrors<IBookingRequest>
}

interface IMapDispatchToProps {
    setTouched: ISetTouchedAction
    changeBookingRequest: IChangeBookingRequestAction
}

type IProps = IMapStateToProps & IMapDispatchToProps & RouteComponentProps

const BookingInformationForm: React.FunctionComponent<IProps> = props => {
    const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false)

    const onChange: IOnChange = (fieldName, value) => {
        props.changeBookingRequest({ [fieldName]: value })
    }

    const isValid = (): boolean => {
        return isEmpty(props.bookingRequestErrors)
    }

    const onSubmit = (): void => {
        isValid() ? props.history.push(SUMMARY_VIEW_URL) : setIsSnackbarOpen(!isSnackbarOpen)
    }

    const onTouched = (fieldName: string) => () => {
        props.setTouched({ [fieldName]: true })
    }
    return (
        <BookingInformationFormComponent
            onTouched={onTouched}
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={onChange}
            onSubmit={onSubmit}
            isSnackbarOpen={isSnackbarOpen}
            setIsSnackbarOpen={setIsSnackbarOpen}
        />
    )
    
}

const mapStateToProps = (state: IReduxState): IMapStateToProps => {
    return {
        bookingRequestErrors: state.bookingRequest.errors,
        errors: pick<IErrors>(state.bookingRequest.errors, formFields),
        values: pick<IBookingRequest>(state.bookingRequest.data, formFields),
        touched: pick<ITouched<IFormValues>>(state.bookingRequest.touched, formFields),
    }
}

export default compose(
    connect<IMapStateToProps, IMapDispatchToProps, {}, IReduxState>(mapStateToProps, {
        changeBookingRequest,
        setTouched,
    }),
    withRouter,
)(BookingInformationForm) as React.ComponentType
