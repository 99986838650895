import React from "react"
import FooterComponent from "./Footer"

class Footer extends React.Component {
    public render() {
        return <FooterComponent {...this.props} />
    }
}

export default Footer
