import React from "react"
import { Theme, withStyles } from "@material-ui/core/styles"
import { createStyles, WithStyles } from "@material-ui/styles"
import StyreWebLogo from "../../assets/img/vincu/StyreWebLogo.png"
import { Grid, Typography, Hidden } from "@material-ui/core"
import cn from "classnames"
import { IBookingRequest } from "../../interfaces/IBookingRequest"

const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "fit-content",
        },
        box: {
            border: "1px solid #c3c3c3",
            [theme.breakpoints.down("sm")]: {
                border: "none",
            },
            padding: theme.spacing(2),
        },
        rightBox: {
            paddingRight: theme.spacing(25),
            [theme.breakpoints.down("md")]: {
                paddingRight: theme.spacing(2),
            },
            borderLeft: "none",
        },
        styreWebLogo: {
            [theme.breakpoints.down("md")]: {
                width: "120px",
            },
        },
    })

type IProps = WithStyles<typeof styles> & { bookingRequest: IBookingRequest }

const StyreWebHeaderComponent: React.FunctionComponent<IProps> = props => {
    const { classes, bookingRequest } = props
    //Styrewebcustomers gets a special styrewebheader
    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.box}>
                <Typography gutterBottom variant="body2">
                    I samarbeid med
                </Typography>
                <img src={StyreWebLogo} alt="styreWebLogo" className={classes.styreWebLogo} />
            </Grid>
            {bookingRequest.fullName && (
                <Hidden smDown>
                    <Grid item className={cn(classes.box, classes.rightBox)}>
                        <Typography variant="body2">{bookingRequest.company}</Typography>
                        <Typography variant="body2">v/{bookingRequest.fullName}</Typography>
                        <Typography variant="body2">
                            {bookingRequest.phone &&
                                bookingRequest.phone?.substring(0, 3) +
                                    " " +
                                    bookingRequest.phone?.substring(3, 5) +
                                    " " +
                                    bookingRequest.phone?.substring(5, 8) +
                                    " " +
                                    bookingRequest.phone?.substring(8, 11)}
                        </Typography>
                        <Typography variant="body2">{bookingRequest.email}</Typography>
                    </Grid>
                </Hidden>
            )}
        </Grid>
    )
}

export default withStyles(styles)(StyreWebHeaderComponent)
