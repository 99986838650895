import { Theme, Typography, Button } from "@material-ui/core"
import { createStyles, withStyles, WithStyles } from "@material-ui/styles"
import cn from "classnames"
import { globalStyles } from "../../styles/themes/vincu"
import React from "react"
import { Link } from "react-router-dom"
import { LANDING_LAYOUT_URL } from "../../routes"
import Statement from "../../components/Statement"
import { data } from "./constants"

export interface IProps {}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(20),
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
                marginBottom: theme.spacing(6),
                [theme.breakpoints.down("xs")]: { padding: theme.spacing(2) },
            },
            [theme.breakpoints.down("xs")]: { textAlign: "center" },
        },
        centerContent: globalStyles.centerContent,
        container: {
            width: "66%",
            [theme.breakpoints.down("md")]: { width: "72%" },
            [theme.breakpoints.down("sm")]: { width: "80%" },
            [theme.breakpoints.down("xs")]: { width: "90%" },
            "& > *": {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(4),
                [theme.breakpoints.down("sm")]: {
                    marginBottom: theme.spacing(6),
                },
            },
        },
        errorCross: {
            marginLeft: theme.spacing(2),
            width: 40,
            height: 40,
            color: theme.palette.error.light,
        },
        contactInfoBox: {
            padding: theme.spacing(4),
            backgroundColor: theme.palette.info.main,
            [theme.breakpoints.down("md")]: { padding: theme.spacing(2) },
        },
        submitButton: {
            borderRadius: 0,
            padding: theme.spacing(3),
            backgroundColor: theme.palette.primary.light,
            color: "#ffffff",
            [theme.breakpoints.down("xs")]: { padding: theme.spacing(1) },
        },
        icon: {
            height: 25,
            width: 25,
            marginRight: theme.spacing(2),
        },
        infoWrapper: {
            padding: theme.spacing(1),
        },
        errorCrossContainer: {
            justifyContent: "center",
        },
    })

interface IErrorComponentProps {}

const Referals: React.FunctionComponent<IErrorComponentProps & WithStyles<typeof styles>> = props => {
    const { classes } = props
    return (
        <div className={cn(classes.root, classes.centerContent)}>
            <Typography variant="h2">Hva våre kunder sier om oss</Typography>
            <div className={classes.container}>
            {
                data.map((referall, index) => {
                    return (
                        <Statement
                            key={index}
                            header={referall.header}
                            subHeader = {referall.subHeader !== undefined ? referall.subHeader : undefined}
                            text={referall.text}
                            image={referall.logo}
                            align={index % 2 === 0 ? "right" : "left"}
                        />
                    )
                })
            }
            </div>
            <Button
                component={Link}
                to={LANDING_LAYOUT_URL}
                variant={"contained"}
                size={"large"}
                className={classes.submitButton}
            >
                Tilbake til forsiden
            </Button>
        </div>
    )
}

export default withStyles(styles)(Referals)
