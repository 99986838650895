import React from "react"
import { IErrors } from "../../validators/bookingRequest.validator"
import ErrorPopupComponent from "./ErrorPopup"

type IProps = {
    isSnackbarOpen: boolean,
    setIsSnackbarOpen: (isSnackbarOpen: boolean) => void,
    errors: IErrors,
    
}

class ErrorPopup extends React.Component<IProps> {
    public render() {
        return <ErrorPopupComponent {...this.props} />
    }
}

export default ErrorPopup
