import { Theme } from "@material-ui/core"
import { WithStyles, withStyles } from "@material-ui/styles"
import React from "react"
import BookingInformation from "../../components/BookingInformationForm"

const styles = (theme: Theme) => {
    return {
        root: {
            height: "100%",
            margin: "auto",
            maxWidth: "70%",
            [theme.breakpoints.down("lg")]: {
                maxWidth: "100%",
            },
            [theme.breakpoints.down("xs")]: {
                maxWidth: "90%",
            },
        },
    }
}

type IProps = WithStyles<typeof styles> & {}

const BookingRequestInfoComponent: React.FunctionComponent<IProps> = props => {
    const { classes } = props
    return (
        <div className={classes.root}>
            <BookingInformation />
        </div>
    )
}

export default withStyles(styles)(BookingRequestInfoComponent)
