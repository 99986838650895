import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
// tslint:disable-next-line:ordered-imports
import { createBrowserHistory } from "history"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Router } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import App from "./App"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import { persistor, store } from "./store"

const hist = createBrowserHistory()

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Router history={hist}>
                <App />
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
