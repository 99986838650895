import React from "react"
import { ILandingLayoutProps } from "../../interfaces/ILandingLayout"
import LandingComponent from "./Landing"

class Landing extends React.Component<ILandingLayoutProps> {
    public render() {
        return <LandingComponent {...this.props} />
    }
}

export default Landing
