import { TextFieldProps } from "@material-ui/core/TextField"
import React from "react"
import { IOnChange } from "../types"
import TextFieldComponent from "./TextField"

export type IProps = Omit<TextFieldProps, "onChange" | "name" | "error" | "onBlur"> & {
    onChange: IOnChange<any>
    name: string
    error?: string
    onTouched?: () => void
}

class TextField extends React.Component<IProps> {
    public render() {
        return <TextFieldComponent {...this.props} />
    }
}

export default TextField
