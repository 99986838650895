import React from "react"
import { Route, Switch } from "react-router-dom"
import { IRoute } from "../interfaces/IRoute"
import rawRoutes from "../routes"

interface IRouterProps {
    layout: string
}
interface IRouterState {
    routes: IRoute[]
}

class Router extends React.Component<IRouterProps, IRouterState> {
    public static getActiveRoute = (routes: IRoute[] = rawRoutes, parentLayout = ""): IRoute | null => {
        let currentRoute: IRoute | null = null
        for (const route of routes) {
            currentRoute = route
            const layout = currentRoute.layout || parentLayout
            if (currentRoute.subRoutes) {
                currentRoute = Router.getActiveRoute(route.subRoutes, layout)
            }
            if (Router.isActiveRoute(layout + route.path)) {
                return route
            }
        }
        return currentRoute
    }

    public static isActiveRoute = (routePath: string) => {
        let path = routePath
        if (path.endsWith("/")) {
            path = path.slice(0, -1)
        }
        return window.location.pathname === path
    }
    constructor(props) {
        super(props)
        this.state = {
            routes: rawRoutes,
        }
    }

    public getRoutes() {
        return this.state.routes.filter(route => route.layout === this.props.layout)
    }

    public renderRoutes = (routes, parentLayout = null) => {
        if (!routes) {
            return []
        }
        return routes.map(route => {
            const layout = route.layout || parentLayout
            const key = layout + route.path
            if (layout !== this.props.layout) {
                return null
            }
            if (route.subRoutes) {
                return [
                    route.component && <Route exact path={layout + route.path} component={route.component} key={key} />,
                    ...this.renderRoutes(route.subRoutes, layout),
                ]
            }
            return [
                <Route exact path={layout + route.path} render={props => <route.component {...props} />} key={key} />,
            ]
        })
    }

    public render() {
        return <Switch>{this.renderRoutes(this.state.routes)}</Switch>
    }
}

export default Router
