import React from "react"
import ContactDropdownComponent from "./ContactDropdown"

export type IProps = {}

class ContactDropdown extends React.Component<IProps> {
    public render() {
        return <ContactDropdownComponent {...this.props} />
    }
}

export default ContactDropdown
